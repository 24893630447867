import { PageName } from '@authenticated/page-constants';
import { ChangeAction } from '@authenticated/policy/models/change-policy.model';
import { AuthenticatorRoute } from '@aws-amplify/ui';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useReadFromCookieOrURL } from '../../helpers/cookie-helpers';
import {
  analyticsCookies,
  sendAnalytics,
  setPageNameAndChannelAnalytics,
  setUpEvents,
} from './helpers/analytics-helpers';

declare const window: any;

const userID = Math.random().toString(20).substring(2, 6);

const useCampaignAnalytics = () => {
  window.adobeAnalytics.campaign = useReadFromCookieOrURL(
    'cid',
    analyticsCookies.campaign,
  );
  window.adobeAnalytics.eVar1 = useReadFromCookieOrURL(
    'att',
    analyticsCookies.attribution,
  );
  window.adobeAnalytics.eVar22 = useReadFromCookieOrURL(
    'homepageBanner',
    analyticsCookies.homepageBanner,
  );
};
export const setClaimSubmittedAnalytics = (
  memberId: string,
  policyId: string,
  claimId: string,
) => {
  window.adobeAnalytics.eVar32 = policyId;
  window.adobeAnalytics.eVar36 = memberId;
  window.adobeAnalytics.eVar37 = claimId;
  sendAnalytics();
};

export const usePageAnalytics = (currentPage: PageName) => {
  useCampaignAnalytics();
  useEffect(() => {
    const analyticsData = setPageNameAndChannelAnalytics(currentPage);
    window.adobeAnalytics.channel = analyticsData.channel;
    window.adobeAnalytics.pageName = analyticsData.pageName;
    window.adobeAnalytics.t();
  }, [currentPage]);
};

export const usePartnerPageAnalytics = (
  currentPage: PageName,
  partner: string,
) => {
  useCampaignAnalytics();
  useEffect(() => {
    if (partner) {
      const analyticsData = setPageNameAndChannelAnalytics(currentPage);
      window.adobeAnalytics.channel = analyticsData.channel;
      window.adobeAnalytics.pageName =
        analyticsData.pageName.trimEnd() + '-' + partner;
      window.adobeAnalytics.t();
    }
  }, [currentPage, partner]);
};

export const setClaimIdAnalytics = (claimId: string) => {
  window.adobeAnalytics.eVar37 = claimId;
  sendAnalytics();
};

export const useAnalytics = () => {
  useEffect(() => {
    window.adobeAnalytics.t();
  }, []);
};

export const setAuthPageAnalytics = (authState?: AuthenticatorRoute) => {
  let page: PageName | undefined;
  switch (authState) {
    case 'authenticated':
      page = 'Login';
      break;
    case 'signOut':
      page = 'Login';
      break;
    default:
      break;
  }
  if (page) {
    const analyticsData = setPageNameAndChannelAnalytics(page);
    window.adobeAnalytics.channel = analyticsData.channel;
    window.adobeAnalytics.pageName = analyticsData.pageName;
    window.adobeAnalytics.t();
  }
};

export const setSessionAnalytics = () => {
  window.adobeAnalytics.eVar13 = userID;
};

export const setHomepageAnalytics = () => {
  Auth.currentUserInfo().then(info => {
    window.adobeAnalytics.eVar13 = info.attributes.sub;
    setEventAnalytics(['event10']);
  });
};

export const setEventAnalytics = (events: string[]) => {
  if (events) {
    window.adobeAnalytics.events = setUpEvents(events);
    sendAnalytics();
  }
};

export const setChangePolicyAnalytics = (changeType: ChangeAction) => {
  let eVar17 = '';
  switch (changeType) {
    case 'AddDependants':
      eVar17 = 'Add partner/child';
      break;
    case 'RemoveDependants':
      eVar17 = 'Remove partner/child';
      break;
    case 'UpgradePlan':
      eVar17 = 'Upgrade';
      break;
    case 'DowngradePlan':
      eVar17 = 'Downgrade';
      break;
    case 'CancelMyPlan':
      eVar17 = 'Cancel Plan';
      break;
    default:
      break;
  }
  window.adobeAnalytics.eVar17 = eVar17;
  sendAnalytics();
};
