import { createImageUrl } from '@shared/helpers/helper-functions';
import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { AppRating } from './components/AppRating';
import { AppStoreLinks } from './components/AppStoreLinks';
import { AppStoreQRCode } from './components/AppStoreQRCode';
import styles from './SimplyPlanApp.module.scss';

interface SimplyPlanAppProps {
  title?: string;
  center?: boolean;
}

export const SimplyPlanApp = ({ title, center }: SimplyPlanAppProps) => {
  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.column_container}>
          <div
            className={`${styles.column_left} ${center && styles.center}`}
            data-testid="simplyPlanAppText">
            <div>
              <div className={styles.title} data-testid="simplyplan-app-title">
                Simple healthcare on the move
              </div>
              <div
                className={styles.text}
                data-testid="simplyplan-app-description">
                Get healthcare anytime, anywhere with the Simplyplan app.
              </div>
              <div className={styles.app_rating}>
                <AppRating />
              </div>
            </div>
            <div className={styles.links}>
              {isMobile ? (
                <div className={styles.app_store}>
                  <AppStoreLinks
                    appleAnalytics="event107"
                    googleAnalytics="event106"
                  />
                </div>
              ) : (
                <div className={styles.qr_code}>
                  <AppStoreQRCode />
                </div>
              )}
            </div>
          </div>
          <div className={styles.column_right}>
            <img
              className={styles.image}
              src={createImageUrl('simplyplan.png')}
              alt="SimplyPlan"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
