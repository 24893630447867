import { Field, useFormikContext } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { FieldElementProps, getName, getPlaceholder } from '../FieldElement';
import styles from '../FieldElement.module.scss';
import { createFieldElementDataNameId } from '../helpers/form.helpers';

export const SortCodeFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;
  const isError =
    formikProps.errors[`${props.inputId}`] &&
    formikProps.touched[`${props.inputId}`];

  const [sortCode, setSortCode] = useState({
    first: props.field.initialValues?.slice(0, 2) || '',
    second: props.field.initialValues?.slice(2, 4) || '',
    third: props.field.initialValues?.slice(4, 6) || '',
  });

  useEffect(() => {
    setFieldValue(
      'sortCode',
      `${sortCode.first}${sortCode.second}${sortCode.third}`,
    );
  }, [setFieldValue, sortCode]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSortCode({
      ...sortCode,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={styles.sort_code_container}>
      <Field
        className={`${props.field.style === 'large' && styles.large} ${
          isError ? styles.error_sort_code : styles.field_sort_code
        }`}
        data-testid={getName(
          createFieldElementDataNameId(
            props.field,
            props.options?.pageName,
            props.options?.elementTypeName,
            '1',
          ),
          props?.index,
          props.options?.useIndexInName,
        )}
        type={props.field.type}
        id={`${props.inputId}-first`}
        placeholder={getPlaceholder(
          props.field.type,
          props.field.placeholderText,
        )}
        name="first"
        value={sortCode.first}
        onChange={(e: any) => {
          handleChange(e);
          if (e.target.value.length === 2) {
            document.getElementById(`${props.inputId}-second`)?.focus();
          }
        }}
        min={props.field.range?.min}
        max={props.field.range?.max}
        step={props.field.range?.step}
        maxLength={2}
      />
      <Field
        className={`${props.field.style === 'large' && styles.large} ${
          isError ? styles.error_sort_code : styles.field_sort_code
        }`}
        data-testid={getName(
          createFieldElementDataNameId(
            props.field,
            props.options?.pageName,
            props.options?.elementTypeName,
            '2',
          ),
          props?.index,
          props.options?.useIndexInName,
        )}
        type={props.field.type}
        id={`${props.inputId}-second`}
        placeholder={getPlaceholder(
          props.field.type,
          props.field.placeholderText,
        )}
        name="second"
        value={sortCode.second}
        onChange={(e: any) => {
          handleChange(e);
          if (e.target.value.length === 2) {
            document.getElementById(`${props.inputId}-third`)?.focus();
          }
        }}
        min={props.field.range?.min}
        max={props.field.range?.max}
        step={props.field.range?.step}
        maxLength={2}
      />
      <Field
        className={`${props.field.style === 'large' && styles.large} ${
          isError ? styles.error_sort_code : styles.field_sort_code
        }`}
        data-testid={getName(
          createFieldElementDataNameId(
            props.field,
            props.options?.pageName,
            props.options?.elementTypeName,
            '3',
          ),
          props?.index,
          props.options?.useIndexInName,
        )}
        type={props.field.type}
        id={`${props.inputId}-third`}
        placeholder={getPlaceholder(
          props.field.type,
          props.field.placeholderText,
        )}
        name="third"
        value={sortCode.third}
        onChange={(e: any) => {
          handleChange(e);
        }}
        min={props.field.range?.min}
        max={props.field.range?.max}
        step={props.field.range?.step}
        maxLength={2}
      />
    </div>
  );
};
