import {
  lowercaseLetter,
  number,
  specialCharacter,
  uppercaseLetter,
} from '@shared/helpers/regex';
import { PasswordValidation } from './PasswordValidation';

export interface PasswordValidationListProps {
  passwordValue: string;
}

export const PasswordValidationList = ({
  passwordValue,
}: PasswordValidationListProps) => {
  return (
    <>
      <PasswordValidation
        title="Include at least 10 characters"
        styling={
          passwordValue === ''
            ? 'empty'
            : passwordValue.length >= 10
              ? 'checkPassed'
              : 'error'
        }
        testid="10-chars"
      />
      <PasswordValidation
        title="Include a number"
        styling={
          passwordValue === ''
            ? 'empty'
            : number.test(passwordValue)
              ? 'checkPassed'
              : 'error'
        }
        testid="number"
      />
      <PasswordValidation
        title="Include a special character (! @ # $ % ^ & * ( ) _ + - = [ ] { } ; ' : &quot; | , . < > ? ~)"
        styling={
          passwordValue === ''
            ? 'empty'
            : specialCharacter.test(passwordValue)
              ? 'checkPassed'
              : 'error'
        }
        testid="special-chars"
      />
      <PasswordValidation
        title="Include an uppercase character"
        styling={
          passwordValue === ''
            ? 'empty'
            : uppercaseLetter.test(passwordValue)
              ? 'checkPassed'
              : 'error'
        }
        testid="uppercase-chars"
      />
      <PasswordValidation
        title="Include a lowercase letter"
        styling={
          passwordValue === ''
            ? 'empty'
            : lowercaseLetter.test(passwordValue)
              ? 'checkPassed'
              : 'error'
        }
        testid="lowercase-chars"
      />
    </>
  );
};
