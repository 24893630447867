export interface FooterLinksModel {
  key: FooterLinksKey;
  display: string;
  link: string;
}

export type FooterLinksKey =
  | "legal"
  | "privacy"
  | "faqs"
  | "complaints"
  | "cookies"
  | "accessibility"
  | "contact"
  | "sitemap";

export const footerLinks: Array<FooterLinksModel> = [
  { 
    key: "legal", 
    display: "Legal", 
    link: "https://www.simplyhealth.co.uk/about-us/legal" 
  },
  { 
    key: "privacy", 
    display: "Privacy", 
    link: "https://www.simplyhealth.co.uk/about-us/privacy" 
  },
  {
    key: "faqs",
    display: "FAQs",
    link: "https://www.simplyhealth.co.uk/frequently-asked-questions/individuals-faq",
  },
  {
    key: "complaints",
    display: "Complaints data",
    link: "https://www.simplyhealth.co.uk/about-us/complaints-data",
  },
  {
    key: "cookies",
    display: "Cookies",
    link: "https://www.simplyhealth.co.uk/about-us/cookies",
  },
  {
    key: "accessibility",
    display: "Accessibility",
    link: "https://www.simplyhealth.co.uk/about-us/accessibility",
  },
  { 
    key: "contact", 
    display: "Contact", 
    link: "https://www.simplyhealth.co.uk/contact-us" 
  },
  { 
    key: "sitemap", 
    display: "Sitemap", 
    link: "https://www.simplyhealth.co.uk/sitemap" 
  },
];
