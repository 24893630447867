import { RoutePath } from "@authenticated/routing/models/route-paths";

export interface Parameter {
  name: string;
  value: string;
}

export const buildPath = (path: RoutePath, params: Array<Parameter>, queryParams?: Array<Parameter>) => {
  let derivedPath = path.path;
  params.forEach((elem) => {
    derivedPath = derivedPath.replace(elem.name, elem.value);
  })
  if (queryParams && queryParams.length > 0) {
    let first: boolean = true;
    queryParams.forEach(queryParam => {
      if (first) {
        derivedPath = derivedPath + "?";
        first = false;
      } else {
        derivedPath = derivedPath + "&";
      }
      derivedPath = derivedPath + queryParam.name + "=" + queryParam.value;
    })
  }
  return derivedPath;
}

