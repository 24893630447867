export type CounsellingType =
  | 'Consumer/CorpVol'
  | 'Corporate F2F'
  | 'Corporate Non F2F'
  | 'Denplan Plus'
  | 'None';

export interface AccessControl {
  hasGPBenefit: boolean;
  hasHealthPlan: boolean;
  hasGPOnly: boolean;
  hasPolicies: boolean;
  isDependantOnly: boolean;
  counsellingType: CounsellingType;
  hasActivePolicies: boolean;
  isDhpMemberOnly: boolean;
}

export const createInitialAccessControl = (): AccessControl => {
  return {
    hasGPBenefit: false,
    hasHealthPlan: false,
    hasGPOnly: false,
    hasPolicies: false,
    isDependantOnly: false,
    counsellingType: 'None',
    hasActivePolicies: false,
    isDhpMemberOnly: false,
  };
};
