import { Field, useFormikContext } from "formik";
import { FieldElementProps, getName, getPlaceholder } from "../FieldElement";
import { createFieldElementDataNameId } from "../helpers/form.helpers";
import styles from "../FieldElement.module.scss";

export const NewInputFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  const isError = formikProps.errors[`${props.inputId}`] && formikProps.touched[`${props.inputId}`];

  return (
    <Field
      className={`${props.field.style === "large" && styles.large} ${
        isError ? styles.error_new : styles.field_new
      }`}
      data-testid={getName(
        createFieldElementDataNameId(
          props.field,
          props.options?.pageName,
          props.options?.elementTypeName
        ),
        props?.index,
        props.options?.useIndexInName
      )}
      type={props.field.type}
      id={props.inputId}
      placeholder={getPlaceholder(props.field.type, props.field.placeholderText)}
      name={props.inputId}
      onChange={(e: any) => {
        formikProps.handleChange(e);
        props.handlers?.onChange(e);
      }}
      min={props.field.range?.min}
      max={props.field.range?.max}
      step={props.field.range?.step}
    />
  );
};
