import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { PageName, PageStatus } from '@authenticated/page-constants';
import { errorToast, successToast } from '@shared/helpers/toast';
import { Layout } from '@unauthenticated/parts/Layout';
import {
  confirmForgotPasswordForm,
  initialValues,
  validationSchema,
} from './form-meta/confirm-forgot-password.form-config';

import { Button } from '@authenticated/parts/buttons/Button';
import { SubmitButton } from '@authenticated/parts/buttons/SubmitButton';
import { FieldElement } from '@authenticated/parts/forms/FieldElement';
import { routePaths } from '@authenticated/routing/models/route-paths';
import { buildPath } from '@shared/helpers/build-dynamic-path';
import { usePageAnalytics } from '@shared/services/analytics/analytics-hooks';
import { preventCopyPaste } from '../../../shared/helpers/prevent-copy-paste';

import { PasswordValidationList } from '@shared/parts/password-validation/PasswordValidationList';
import styles from './ConfirmForgotPassword.module.scss';

export const ConfirmForgotPassword = () => {
  const [pageStatus, setPageStatus] = useState<PageStatus>('Loaded');
  const [username, setUsername] = useState<any | null>('');
  const [code, setCode] = useState<any | null>('');
  const [stage, setStage] = useState(1);
  const [origin, setOrigin] = useState<string | null>(null);
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [pageName, setPageName] = useState<PageName>(
    'ForgotPasswordNewPassword',
  );

  const [searchParams] = useSearchParams();

  usePageAnalytics(pageName);

  useEffect(() => {
    if (stage === 2) {
      setPageName('ForgotPasswordNewPasswordConfirmation');
    }
  }, [stage]);

  useEffect(() => {
    if (searchParams) {
      const id = searchParams.get('id');
      const number = searchParams.get('confirmation_code');
      const origin = searchParams.get('origin');
      setUsername(id);
      setCode(number);

      !!origin && setOrigin(window.atob(origin));
    }
  }, [searchParams]);

  const handleSubmit = (values: any) => {
    setPageStatus('Sending');
    Auth.forgotPasswordSubmit(username, code, values.password)
      .then(() => {
        successToast('Success', pageName);
        setStage(2);
        setPageStatus('Loaded');
      })
      .catch(err => {
        setPageStatus('Loaded');
        errorToast(err.message, pageName);
      });
  };

  return (
    <Fragment>
      {pageStatus === 'Next Page' ? (
        <Navigate to={buildPath(routePaths.home, [])} />
      ) : (
        <>
          {stage === 1 ? (
            <Layout
              title="Password assistance"
              imageSource="Key_Password_reset.png"
              imageAltText="Unlock">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <Form>
                  <FieldElement
                    field={confirmForgotPasswordForm.password}
                    handlers={{
                      onChange: e => {
                        setPasswordValue(e.target.value);
                      },
                      onCopy: preventCopyPaste,
                      onPaste: preventCopyPaste,
                    }}
                    options={{
                      hideErrorMessage: true,
                      poundValidation: true,
                      pageName,
                    }}></FieldElement>
                  <span className={styles.password_header}>Password must:</span>
                  <ul className={styles.password_requirements}>
                    <PasswordValidationList passwordValue={passwordValue} />
                  </ul>
                  <FieldElement
                    handlers={{
                      onCopy: preventCopyPaste,
                      onPaste: preventCopyPaste,
                    }}
                    options={{ pageName }}
                    field={
                      confirmForgotPasswordForm.confirmPassword
                    }></FieldElement>
                  <SubmitButton
                    buttonClickedText="Updating password..."
                    buttonName="ChangePassword"
                    buttonText="Update my password"
                    pageName={pageName}
                    styling="send"
                    pageStatus={pageStatus}></SubmitButton>
                </Form>
              </Formik>
            </Layout>
          ) : (
            <Layout
              title="Success! Your password has been updated."
              imageSource="Celebration_AppIllustrations.png"
              imageAltText="Successful">
              <p className={styles.info} data-testid="success-message">
                You can now use your updated password to access your account.
              </p>
              <Button
                name="BackToSignin"
                pageName={pageName}
                type="button"
                styling="send"
                onClickHandler={() => {
                  !!origin
                    ? (window.location.href = window.location.origin + origin)
                    : setPageStatus('Next Page');
                }}>
                Sign in
              </Button>
            </Layout>
          )}
        </>
      )}
    </Fragment>
  );
};
