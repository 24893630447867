import { lowDate } from "@shared/helpers/date-helpers";
import { PolicySummaryModel } from "@authenticated/services/models/policy-list.models";
import { PersonDataModel, PolicyDataModel } from "@authenticated/services/models/policy-data.models";
import { BenefitEntitlementsModel } from "@authenticated/claims/models/benefit-entitlements.model";

export interface MakeAClaimStateModel {
  receipts: ReceiptData;
  claim: ClaimStateData;
  dentalAccident: DentalAccidentStateData;
}

export const createInitialClaimStateModel = (): MakeAClaimStateModel => {
  return {
    receipts: {},
    claim: {
      treatmentDate: lowDate,
      policyId: "",
      claimantId: "",
      treatmentId: "",
      treatmentPrice: 0,
      practitioner: "",
    },
    dentalAccident: {},
  };
};

export interface ReceiptData {
  [key: string]: UploadedReceiptData;
}

export interface UploadedReceiptData {
  id: string;
  file: File;
  receiptId: string;
}

export interface ClaimStateData {
  treatmentDate: Date;
  policyId: string;
  claimantId: string;
  treatmentId: string;
  treatmentPrice?: number;
  practitioner: string;
  dischargeDate?: Date;
  memberPolicies?: Array<PolicySummaryModel>;
  claimants?: Array<PersonDataModel>;
  treatments?: Array<BenefitEntitlementsModel>;
  policy?: PolicyDataModel;
}

export interface DentalAccidentStateData {
  accidentDate?: string;
  accidentDetails?: string;
  injuries?: string;
  medicalAttentionDate?: string;
  medicalAttentionTime?: string;
  treatmentReceivedFrom?: string;
  regularDentist?: string;
  furtherTreatment?: string;
}
