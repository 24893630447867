import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import {
  lowercaseLetter,
  number,
  regexValue,
  specialCharacter,
  uppercaseLetter,
} from '../../../../shared/helpers/regex';
import { FieldElementProps, getName, getPlaceholder } from '../FieldElement';
import styles from '../FieldElement.module.scss';
import { createFieldElementDataNameId } from '../helpers/form.helpers';

export const PasswordFieldLayout = (props: FieldElementProps) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordValue, setPasswordValue] = useState<string>('');
  const formikProps = useFormikContext();
  useEffect(() => {
    const validation = passwordValue.includes('£');
    props.setPoundsValidation(validation);
  }, [passwordValue, props]);
  const passwordValidation =
    passwordValue === ''
      ? null
      : !number.test(passwordValue) ||
          !lowercaseLetter.test(passwordValue) ||
          !uppercaseLetter.test(passwordValue) ||
          !specialCharacter.test(passwordValue) ||
          !regexValue.test(passwordValue)
        ? styles.error
        : null;
  return (
    <div className={styles.password_wrapper}>
      <Field
        className={`${styles.password} ${passwordValidation}`}
        data-testid={getName(
          createFieldElementDataNameId(
            props.field,
            props.options?.pageName,
            props.options?.elementTypeName,
          ),
          props?.index,
          props.options?.useIndexInName,
        )}
        type={passwordVisible ? 'text' : 'password'}
        placeholder={getPlaceholder(
          props.field.type,
          props.field.placeholderText,
        )}
        name={props.inputId}
        id={props.inputId}
        onChange={(e: any) => {
          setPasswordValue(e.target.value);
          formikProps.handleChange(e);
          props.handlers?.onChange && props.handlers.onChange(e);
        }}
        onCopy={props.handlers?.onCopy}
        onPaste={props.handlers?.onPaste}
      />
      <span
        className={styles.password_toggle_icon_wrapper}
        onClick={() => setPasswordVisible(!passwordVisible)}
        data-testid="passwordImage">
        {passwordVisible ? (
          <img
            className={styles.password_toggle_icon}
            src="/member-portal/images/password-visible-off.svg"
            alt="Password not visible"
          />
        ) : (
          <img
            className={styles.password_toggle_icon}
            src="/member-portal/images/password-visible.svg"
            alt="Password visible"
          />
        )}
      </span>
    </div>
  );
};
