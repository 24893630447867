
export interface DownloadBrowserModel {
  title: string;
  shortName: string;
  imageFileName: string;
  link: string;
}

export const downloadBrowserModel: Array<DownloadBrowserModel> = [
  { title: "Google Chrome", shortName: "Chrome", imageFileName: "chrome-icon.png", link: "https://www.google.com/intl/en_uk/chrome/" },
  { title: "Mozilla Firefox", shortName: "Firefox", imageFileName: "firefox-icon.png", link: "https://www.mozilla.org/en-GB/firefox/new/" },
  { title: "Safari", shortName: "Safari", imageFileName: "safari-icon.png", link: "https://support.apple.com/en-us/HT204416" },
  { title: "Microsoft Edge", shortName: "Edge", imageFileName: "edge-icon.png", link: "https://www.microsoft.com/en-us/edge" }
];
