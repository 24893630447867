import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export const generateRandomString = () => Math.random().toString(20).substring(2, 6);

export const createImageUrl = (fileName: string) => `${process.env.REACT_APP_URL_PREFIX}/member-portal/images/${fileName}`;

export const removeDuplicates = (arr, prop) => {
  const unique = new Map();

  arr.forEach((obj) => {
    if (!unique.has(obj[prop])) {
      unique.set(obj[prop], obj);
    }
  });

  return Array.from(unique.values());
}
export const useOAuthUserInfo = () => {
  const [oauthSub, setOauthSub] = useState();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const info = await Auth.currentUserInfo();
        if (info) {
          setOauthSub(info.attributes.sub);
        }
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  return oauthSub;
}