import { ErrorMessage } from "formik";
import { ChangeEventHandler, Dispatch, SetStateAction, useState } from "react";
import { BooleanSchema, DateSchema, NumberSchema, StringSchema } from "yup";

import { PageName } from "@authenticated/page-constants";
import { createFieldElementDataNameId, FieldMeta } from "./helpers/form.helpers";
import errorImage from "@assets/images/error.svg";
import issueImage from "@assets/images/grey-info.svg";
import styles from "./FieldElement.module.scss";

import {
  DateOfBirthFieldLayout,
  PasswordFieldLayout,
  SelectLayout,
  RadioButtonLayout,
  InputFieldLayout,
  PriceFieldLayout,
  CheckboxFieldLayout,
  TextAreaFieldLayout,
  EmailFieldLayout,
  FlatpickrFieldLayout,
  FlatpickrTimeFieldLayout,
  MuiPickerFieldLayout,
  NewInputFieldLayout,
  SortCodeFieldLayout,
} from "./layouts";

export interface FieldElementProps {
  field: FieldMeta;
  inputId?: string;
  index?: number;
  options?: FieldElementOptions;
  handlers?: FieldElementHandlers;
  fieldNamePrefix?: string;
  prefix?: string;
  setPoundsValidation?: any;
  skipLabel?: boolean;
}
export interface FieldElementOptions {
  pageName?: PageName;
  elementTypeName?: string;
  postFix?: string | number;
  useIndexInName?: boolean;
  labelBold?: boolean;
  labelBoldNew?: boolean;
  hideErrorMessage?: boolean;
  registerLabel?: boolean;
  poundValidation?: boolean;
  validation?: boolean;
  validationSchema?: string;
  minClaimDate?: Date;
  maxClaimDate?: Date;
  id?: number;
  treatmentDate?: Date;
  medicalAttentionDate?: string;
  hideRequiredInLabel?: boolean;
}
export interface FieldElementHandlers {
  onChange?: ChangeEventHandler<HTMLInputElement> | any;
  onCopy?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  setDifferentValue?: Dispatch<SetStateAction<boolean>>;
}

export const hasRequiredSet = (schema: StringSchema | DateSchema | BooleanSchema | NumberSchema) =>
  !!schema.describe().tests.find((t) => t.name === "required");

export const getName = (name: string, index?: number, useIndexInName?: boolean, fieldNamePrefix?: string) => {
  const nameToUse: string = useIndexInName && index !== undefined ? name.replace("{index}", index?.toString()) : name;
  return fieldNamePrefix ? fieldNamePrefix + "." + nameToUse : nameToUse;
};

export const getPlaceholder = (type: string, placeholderText?: string): string => {
  return type === "date" ? "dd/mm/yyyy" : placeholderText ? placeholderText : "";
};

export const FieldElement = (props: FieldElementProps) => {
  const [poundsValidation, setPoundsValidation] = useState<boolean>(false);
  const inputId = getName(props.field.name, props?.index, props.options?.useIndexInName, props.fieldNamePrefix);
  const inputProps = { ...props, inputId, setPoundsValidation };

  return (
    <div
      className={
        props.field.type === "checkbox"
          ? styles.form_checkbox_field_container
          : props.field.type === "password"
            ? styles.form_password_field_container
            : props.field.type === "radio"
              ? styles.form_radio_field_container
              : styles.form_field_container
      }
    >
      {!props.skipLabel && (<div className={styles.labels_container}>
        <label
          data-name-id={getName(
            createFieldElementDataNameId(props.field, props.options?.pageName, "label"),
            props?.index,
            props.options?.useIndexInName
          )}
          htmlFor={inputId}
          className={
            props.options?.labelBold
              ? styles.field_label_bold
              : props.options?.labelBoldNew
                ? styles.field_label_bold_new
                : props.options?.registerLabel
                  ? styles.register_label
                  : styles.field_label
          }
        >
          {props.field.label}
          {props.options?.registerLabel || props.options?.hideRequiredInLabel ? null : hasRequiredSet(props.field.schema) && " (required)"}
        </label>
      </div>)}
      {props.field.type === "radio"
        ? RadioButtonLayout(inputProps)
        : props.field.type === "select"
          ? SelectLayout(inputProps)
          : props.field.type === "password"
            ? PasswordFieldLayout(inputProps)
            : props.field.type === "flatpickr"
              ? FlatpickrFieldLayout(inputProps)
              : props.field.type === "flatpickrTime"
                ? FlatpickrTimeFieldLayout(inputProps)
                : props.field.type === "email"
                  ? EmailFieldLayout(inputProps)
                  : props.field.type === "textarea"
                    ? TextAreaFieldLayout(inputProps)
                    : props.field.type === "dateOfBirth"
                      ? DateOfBirthFieldLayout(inputProps)
                      : props.field.type === "checkbox"
                        ? CheckboxFieldLayout(inputProps)
                        : props.field.type === "price"
                          ? PriceFieldLayout(inputProps)
                          : props.field.type === "muipicker"
                            ? MuiPickerFieldLayout(inputProps, props.handlers?.setDifferentValue)
                            : props.field.type === "newText"
                              ? NewInputFieldLayout(inputProps)
                              : props.field.type === "sortCode"
                                ? SortCodeFieldLayout(inputProps)
                                : InputFieldLayout(inputProps)}
      {!props.options?.hideErrorMessage && (
        <ErrorMessage name={inputId}>
          {(msg) => (
            <div className={props.field.type === "newText" ? styles.error_message_new : styles.error_message}>
              <img src={errorImage} className={styles.error_image} alt="Error" />
              {msg}
            </div>
          )}
        </ErrorMessage>
      )}
      {props.options?.poundValidation && poundsValidation && (
        <div className={styles.issue_message}>
          <img src={issueImage} className={styles.issue_image} alt="Error" />£ is not an accepted special character
        </div>
      )}
    </div>
  );
};
