import {
  AmplifyProvider,
  Authenticator,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import { setAuthPageAnalytics } from '@shared/services/analytics/analytics-hooks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  components,
  services,
} from '@unauthenticated/amplify-configuration/AmplifyConfiguration';
import { routePaths } from '@unauthenticated/routing/models/route-paths';
import { UnauthenticatedApp } from '@unauthenticated/unauthenticated-app/UnauthenticatedApp';
import React, { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// Uncomment to enable React query dev tools to view the cache in the browser. Also uncomment the component below
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const AuthenticatedApp = React.lazy(
  () => import('./authenticated/authenticated-app/AuthenticatedApp'),
);

export const ApplicationView = () => {
  const queryClient = new QueryClient();

  const { route } = useAuthenticator(context => [context.route]);
  useEffect(() => {
    setAuthPageAnalytics(route);
  }, [route]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route
            path={`${routePaths.account.path}/*`}
            element={<UnauthenticatedApp />}
          />
          <Route
            path="*"
            element={
              <AmplifyProvider>
                <Authenticator
                  hideSignUp={true}
                  components={components}
                  loginMechanisms={['email']}
                  services={services}>
                  <Suspense fallback={<p>loading</p>}>
                    <AuthenticatedApp />
                  </Suspense>
                </Authenticator>
              </AmplifyProvider>
            }
          />
        </Routes>
      </Router>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
};
