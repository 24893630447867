import { useLocation } from "react-router-dom";
import { Query } from "../services/analytics/helpers/analytics-helpers";

export const useReadFromCookieOrURL = (query: Query, cookie: string) => {
  const queryParam = useQuery().get(query);
  const value = getCookie(cookie, document.cookie.split(";")) || queryParam;
  if (value !== undefined && value !== null) {
    writeToCookie(value, cookie);
  }
  return value;
};

export const writeToCookie = (value: string, cookie: string) => {
  if (value) {
    document.cookie = `${cookie}=${value}`;
  }
};

export const getCookie = (name: string, cookies: string[]) => {
  for (let i = 0; i < cookies.length; i++) {
    const cookiePair = cookies[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
