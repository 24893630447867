export interface RoutePathAccess {
  healthPlanOnly: boolean; // Only accessible to customers with a health plan
  gpOnly: boolean; // Controls whether someone with only a policy that has just the GP benefit can access
  noPolicies: boolean; // Controls whether someone with no policies can access
  dependantCanAccess: boolean; // Controls whether someone who is only a dependant on a policy can access (Overrides noPolicies)
  dhpOnly: boolean; // Controls whether someone with only a DHP policy can access
}

const allCustomers: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: true,
  noPolicies: true,
  dependantCanAccess: true,
  dhpOnly: true,
};

const allCustomersApartFromDhpOnly: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: true,
  noPolicies: true,
  dependantCanAccess: true,
  dhpOnly: false,
};

const allCustomersWithPolicies: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: true,
  noPolicies: false,
  dependantCanAccess: true,
  dhpOnly: true,
};

const allPolicyHoldersWithPolicies: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: true,
  noPolicies: false,
  dependantCanAccess: false,
  dhpOnly: true,
};

const allCustomersWithPoliciesApartFromGPOnly: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: false,
  noPolicies: false,
  dependantCanAccess: true,
  dhpOnly: true,
};

const allCustomersWithPoliciesApartFromGPOnlyAndDhpOnly: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: false,
  noPolicies: false,
  dependantCanAccess: true,
  dhpOnly: false,
};

const allPolicyholdersWithPoliciesApartFromGPOnly: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: false,
  noPolicies: false,
  dependantCanAccess: false,
  dhpOnly: true,
};

const allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly: RoutePathAccess = {
  healthPlanOnly: false,
  gpOnly: false,
  noPolicies: false,
  dependantCanAccess: false,
  dhpOnly: false,
};

const allHealthPlanCustomersWithPolicies: RoutePathAccess = {
  healthPlanOnly: true,
  gpOnly: true,
  noPolicies: false,
  dependantCanAccess: true,
  dhpOnly: true,
};

const allHealthPlanPolicyHoldersWithPolicies: RoutePathAccess = {
  healthPlanOnly: true,
  gpOnly: true,
  noPolicies: false,
  dependantCanAccess: false,
  dhpOnly: true,
};

const allHealthPlanCustomers: RoutePathAccess = {
  healthPlanOnly: true,
  gpOnly: true,
  noPolicies: true,
  dependantCanAccess: true,
  dhpOnly: true,
};

const allHealthPlanCustomersWithPoliciesApartFromGPOnly: RoutePathAccess = {
  healthPlanOnly: true,
  gpOnly: false,
  noPolicies: false,
  dependantCanAccess: true,
  dhpOnly: true,
};

export const routePathAccessTypes = {
  allCustomers,
  allCustomersApartFromDhpOnly,
  allCustomersWithPolicies,
  allCustomersWithPoliciesApartFromGPOnly,
  allCustomersWithPoliciesApartFromGPOnlyAndDhpOnly,
  allHealthPlanCustomers,
  allHealthPlanCustomersWithPolicies,
  allHealthPlanCustomersWithPoliciesApartFromGPOnly,
  allHealthPlanPolicyHoldersWithPolicies,
  allPolicyHoldersWithPolicies,
  allPolicyholdersWithPoliciesApartFromGPOnly,
  allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
};
