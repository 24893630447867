import error from '@assets/images/error.svg';
import greyInfo from '@assets/images/grey-info.svg';
import tick from '@assets/images/tick.svg';
import styles from './PasswordValidation.module.scss';

export interface PasswordValidationProps {
  styling: 'error' | 'checkPassed' | 'empty';
  title: string;
  testid?: string;
}

export const PasswordValidation = ({
  styling,
  title,
  testid,
}: PasswordValidationProps) => {
  const className =
    styling === 'empty'
      ? styles.list
      : styling === 'checkPassed'
        ? styles.password_check_passed
        : styles.error;
  const image =
    styling === 'empty' ? greyInfo : styling === 'checkPassed' ? tick : error;
  return (
    <li className={className} data-testid={testid ? testid : ''}>
      <img
        className={styles.img}
        src={image}
        alt={styling}
        data-testid="image"
      />
      {title}
    </li>
  );
};
