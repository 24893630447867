import { formatDateForApi } from "@shared/helpers/date-helpers";
import { secureGet, securePatch } from "@shared/services/api.service";
import { UpdateResponse } from "./models/common.interfaces";
import {
  EntitlementsResponse,
  GetBenefitsResponse,
  GetServicePartnerTokenResponse,
  MemberPoliciesResponse,
  MemberResponse,
  UpdateMemberRequest,
} from "./models/member-service.interfaces";

export const getMember = async (memberId: String): Promise<MemberResponse> => {
  const baseUrl = process.env.REACT_APP_HEALTHPLAN_API_BASE_URL;
  const featureUrl = "/members/" + memberId;

  const queryParameters: any = {};

  return secureGet<MemberResponse>(baseUrl, featureUrl, queryParameters);
};

export const getMemberPolicies = async (memberId?: String): Promise<MemberPoliciesResponse> => {
  const baseUrl = process.env.REACT_APP_HEALTHPLAN_API_BASE_URL;
  const featureUrl = "/policies";

  const queryParameters: any = {
    "page[number]": 0,
    "page[size]": 100,
  };

  if (memberId) {
    queryParameters.memberId = memberId;
  }

  return secureGet<MemberPoliciesResponse>(baseUrl, featureUrl, queryParameters);
};

export const getEntitlements = async (
  memberId: String,
  policyId: String,
  asOf: Date,
  policyholderId: string
): Promise<EntitlementsResponse> => {
  const baseUrl = process.env.REACT_APP_HEALTHPLAN_API_BASE_URL;
  const featureUrl = "/members/" + memberId + "/benefitentitlements";

  const queryParameters: any = {
    policyId: policyId,
    asOfDate: formatDateForApi(asOf),
    "page[number]": 0,
    "page[size]": 100,
    memberId: policyholderId,
  };

  return secureGet<EntitlementsResponse>(baseUrl, featureUrl, queryParameters);
};

export const getMemberBenefits = async (memberId, asOf?: Date): Promise<GetBenefitsResponse> => {
  const baseUrl = process.env.REACT_APP_HEALTHPLAN_API_BASE_URL;
  const featureUrl = `/members/${memberId}/benefits`;

  const queryParameters: any = {
    "page[number]": 0,
    "page[size]": 100,
  };
  if (asOf) {
    queryParameters.asOfDate = formatDateForApi(asOf);
  }

  return secureGet<any>(baseUrl, featureUrl, queryParameters);
};

export const updateMember = async (memberId: string, request: UpdateMemberRequest): Promise<UpdateResponse> => {
  const baseUrl = process.env.REACT_APP_HEALTHPLAN_API_BASE_URL;
  const featureUrl = "/members/" + memberId;

  const queryParameters: any = {};

  const header = { "Content-Type": "application/vnd.api+json" };

  return securePatch(baseUrl, featureUrl, request, queryParameters, header);
};


export const getServicePartnerToken = async (memberId: string, partner: ServicePartnerType): Promise<GetServicePartnerTokenResponse> => {
  const baseUrl = process.env.REACT_APP_HEALTHPLAN_API_BASE_URL;
  const featureUrl = "/members/" + memberId + "/servicepartnertoken/" + partner;

  const queryParameters: any = {};

  return secureGet(baseUrl, featureUrl, queryParameters);
}

export type ServicePartnerType = 'spectrum';
