import { Field } from "formik";

import { FieldElementProps, getName } from "../FieldElement";
import { createFieldElementDataNameId } from "../helpers/form.helpers";
import styles from "../FieldElement.module.scss";
import { useState } from "react";

export const RadioButtonLayout = (props: FieldElementProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    props.field.initialValues ? props.field.initialValues : ""
  );

  return (
    <div className={styles.radios_container} role='radiogroup' aria-labelledby={props.inputId}>
      {props.field.items?.map((item, index) => {
        return (
          <label
            key={index}
            className={`${props.field.style === "large" && styles.radio_large} ${
              selectedValue.toString() === item.value.toString()
                ? styles.radio_label_selected
                : styles.radio_label_unselected
            }`}
            data-name-id={getName(
              createFieldElementDataNameId(
                props.field,
                props.options?.pageName,
                "label",
                item.value
              ),
              props?.index,
              props.options?.useIndexInName
            )}
            htmlFor={props.inputId + item.value}
          >
            <Field
              className={styles.radio_button_field}
              data-testid={getName(
                createFieldElementDataNameId(
                  props.field,
                  props.options?.pageName,
                  props.options?.elementTypeName,
                  item.value
                ),
                props?.index,
                props.options?.useIndexInName
              )}
              type={props.field.type}
              name={props.inputId}
              value={item.value}
              id={props.inputId + item.value}
              onClick={(e: any) => {
                setSelectedValue(e.target.value.toString());
              }}
            />
            {item.label}
          </label>
        );
      })}
    </div>
  );
};
