import { StringSchema, DateSchema, BooleanSchema, NumberSchema } from "yup";
import { createElementDataNameId } from "@shared/helpers/create-element-data-name-id";

export type ElementType = "text" | "password" | "date" | "email" | "radio" | "select" | "textarea" | "checkbox" | "number" | "time" | "price" | "dateOfBirth" | "flatpickr" | "flatpickrTime" | "muipicker" | "newText" | "sortCode";
export type StyleType = "large";

export interface FieldMeta {
  name: string;
  label: string;
  type: ElementType;
  style?: StyleType;
  schema: StringSchema | DateSchema | BooleanSchema | NumberSchema;
  items?: Array<ItemMeta>;
  range?: RangeMeta;
  placeholderText?: string;
  initialValues?: any;
}

export interface RangeMeta {
  min: number | string;
  max: number | string;
  step?: number;
}

export interface ItemMeta {
  value: string;
  label: string;
}

export function createFieldElementDataNameId(
  field: FieldMeta,
  pageName?: string,
  elementType?: string,
  postFix?: string
): string {
  if (!pageName) {
    pageName = "general";
  }
  if (!elementType) {
    elementType = field.type;
  }
  const dataName = createElementDataNameId(field?.name, pageName, elementType, postFix);
  return dataName;
}
