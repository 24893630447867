import { Auth } from "aws-amplify";
import { Fragment, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { Layout } from "@unauthenticated/parts/Layout";
import { PageName, PageStatus } from "@authenticated/page-constants";
import { Button } from "@authenticated/parts/buttons/Button";
import { Spinner } from "@authenticated/parts/spinner/Spinner";
import { routePaths } from "@authenticated/routing/models/route-paths";
import { buildPath } from "@shared/helpers/build-dynamic-path";
import { errorToast, successToast } from "@shared/helpers/toast";
import { setEventAnalytics, usePageAnalytics } from "@shared/services/analytics/analytics-hooks";
import styles from "./ConfirmEmail.module.scss";

const PAGE_NAME: PageName = "RegisterConfirmEmail";

export const ConfirmEmail = () => {
  const [username, setUsername] = useState<any | null>("");
  const [code, setCode] = useState<any | null>("");
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loading");
  const [message, setMessage] = useState("");
  const [origin, setOrigin] = useState<string | null>(null);

  usePageAnalytics(PAGE_NAME);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      const id = searchParams.get("id");
      const number = searchParams.get("confirmation_code");
      const origin = searchParams.get("origin");
      setUsername(id);
      setCode(number);
      setOrigin(!!origin ? window.atob(origin) : origin);
    }
  }, [searchParams]);

  useEffect(() => {
    if (username && code) {
      Auth.confirmSignUp(username, code)
        .then((data) => {
          setEventAnalytics(["event10"]);
          successToast(data, PAGE_NAME);
          setPageStatus("Loaded");
          setMessage("Your email has been verified");
        })
        .catch((err) => {
          if (err.message === "Invalid verification code provided, please try again.") {
            err.message = "The link has expired or invalid, please request a new confirmation email again.";
          } else if (err.message === "Invalid code provided, please request a code again.") {
            err.message = "The link has expired or invalid, please request a new confirmation email again.";
          }
          errorToast(err.message, PAGE_NAME);
          setMessage(err.message);
          setPageStatus("Loaded");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, code]);

  const resendCode = () => {
    Auth.resendSignUp(username)
      .then(() => {
        successToast("Email resent successfully", PAGE_NAME);
      })
      .catch((error) => {
        errorToast(error.message, PAGE_NAME);
      });
  };

  const redirectToLogin = () => {
    if (!!origin) window.location.href = window.location.origin + origin;
    else setPageStatus("Next Page");
  };

  return (
    <Fragment>
      {pageStatus === "Loading" ? (
        <Spinner message="Loading" />
      ) : pageStatus === "Next Page" ? (
        <Navigate to={buildPath(routePaths.home, [])} />
      ) : (
        <Layout title="Confirm registration" imageSource="Emails_AppIllustrations.png" imageAltText="Error">
          <div>
            <p data-testid="confirmEmailMessage">{message}</p>
          </div>
          <div>
            <Button name="Login" pageName={PAGE_NAME} onClickHandler={redirectToLogin} styling="send" type="button">
              Login
            </Button>
            <br />
            <div className={styles.links}>
              <p className={styles.title}>Have an error?</p>
              <p className={styles.footer_info}>
                Remember to check your spam and junk folder.
                <Button
                  pageName={PAGE_NAME}
                  onClickHandler={resendCode}
                  styling="anchor"
                  name="ResendCode"
                  type="button"
                >
                  Resend email.
                </Button>
              </p>
            </div>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};
