import { useRouteMatchWithChildPath } from '@unauthenticated/routing/models/route-paths';
import QRCode from 'react-qr-code';
import styles from './AppStoreQRCode.module.scss';

export const AppStoreQRCode = () => {
  const unauthenticatedRoutePaths = useRouteMatchWithChildPath();
  const url =
    window.location.protocol +
    '//' +
    window.location.host +
    unauthenticatedRoutePaths.appStore.path;

  return (
    <div className={styles.container}>
      <QRCode className={styles.qrCode} value={url} data-testid="qr-code" />
      <div className={styles.text} data-testid="qr-code-title">
        Scan me now!
      </div>
    </div>
  );
};
