// eslint-disable-next-line
const regexValue = /^.*(?=.{10,})(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.*[A-Z])(?=.*[a-zA-Z]).*$/;
// eslint-disable-next-line
const specialCharacter = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
// eslint-disable-next-line
const regexEmailValue = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)/;
const number = /\d/;
const lowercaseLetter = /[a-z]/;
const uppercaseLetter = /[A-Z]/;
const regexName = /^[A-Za-z \-']*$/;
const regexPolicyNumber = /^[a-zA-Z0-9]{0,17}$/;
const regexPostcode = /^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$/;

export { regexValue, specialCharacter, regexEmailValue, number, lowercaseLetter, uppercaseLetter, regexName, regexPostcode, regexPolicyNumber };