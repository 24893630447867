import { getBrowserName } from "../helper/GetBrowserName";
import {DownloadBrowserModel, downloadBrowserModel} from "../models/DownloadBrowserModel";
import { DownloadBrowserCard } from "./DownloadBrowserCard";
import styles from "./DownloadBrowserGrid.module.scss";

export const DownloadBrowserGrid = () => {
  const browserName = getBrowserName()
  
const displayBrowsers = downloadBrowserModel.filter(browser => browser.shortName !== browserName) as DownloadBrowserModel[]

return (
    <div className={styles.outer_container} data-testid="browserCardGrid">
      {displayBrowsers.map((model, index) => (
            <DownloadBrowserCard 
            model={model}
            key={index}
            buttonText="Download"
            hasButton={model.shortName !== "Safari"} />
      ))}
    </div>
  );
}