import appStar from '@assets/images/green-star.svg';
import { useItunesQuery } from '@authenticated/services/queries/itunes.query';
import { useMemo } from 'react';
import styles from './AppRating.module.scss';

export const AppRating = () => {
  const { itunesData, itunesIsLoading } = useItunesQuery();

  const displayAppRating = useMemo(() => {
    if (
      !itunesIsLoading &&
      itunesData?.appRating &&
      +itunesData?.appRating > 3
    ) {
      return true;
    } else {
      return false;
    }
  }, [itunesData, itunesIsLoading]);
  return (
    <>
      {displayAppRating && itunesData && (
        <div className={styles.rating} data-testid="ratings">
          {+itunesData.appRating >= 0.5 && (
            <img src={appStar} alt="star" className={styles.rating_star} />
          )}
          {+itunesData.appRating >= 1.5 && (
            <img src={appStar} alt="star" className={styles.rating_star} />
          )}
          {+itunesData.appRating >= 2.5 && (
            <img src={appStar} alt="star" className={styles.rating_star} />
          )}
          {+itunesData.appRating >= 3.5 && (
            <img src={appStar} alt="star" className={styles.rating_star} />
          )}
          {+itunesData.appRating >= 4.5 && (
            <img src={appStar} alt="star" className={styles.rating_star} />
          )}
          <div className={styles.rating_container}>
            <div className={styles.rating_number}>{itunesData?.appRating}</div>
            <div className={styles.rating_text}>on the Apple store</div>
            <div className={styles.rating_reviews}>
              ({itunesData?.numberOfReviews}+ reviews)
            </div>
          </div>
        </div>
      )}
    </>
  );
};
