import { queryGCTime, queryStaleTime } from "@shared/constants/site-constants";
import { useQuery } from "@tanstack/react-query";
import fetchJsonp from "fetch-jsonp";
import { mapItunesResponse } from "../mappers/itunes.mapper";

export const useItunesQuery = () => {
    const { data, isError, isLoading } = useQuery({
        queryKey: ['itunes'],
        queryFn: () =>
            getItunesData().then(response => response.json()).then(response => mapItunesResponse(response)),
        enabled: true,
        staleTime: queryStaleTime,
        gcTime: queryGCTime,
        refetchOnWindowFocus: false,
    });
    return {
        itunesData: data,
        itunesIsError: isError,
        itunesIsLoading: isLoading,
    };

}

const getItunesData = () => {
    const url = 'https://itunes.apple.com/lookup?id=1553287152&country=gb'
    return fetchJsonp(url)
}


