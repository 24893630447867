import Flatpickr from "react-flatpickr";
import { FieldElementProps } from "../FieldElement";
import { useFormikContext } from "formik";
import styles from "../FieldElement.module.scss";
import { DateTime } from "luxon";

export const FlatpickrTimeFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  const disabled = props.options?.treatmentDate && props.options.medicalAttentionDate;

  return (
    <>
      {!disabled ? (
        <div>
          <input className={styles.disabled_time} type="text" disabled placeholder="HH:mm" />
        </div>
      ) : (
        <div>
          <Flatpickr
            id={props.inputId}
            name={props.inputId}
            options={{ enableTime: true, noCalendar: true, dateFormat: "H:i", time_24hr: true }}
            placeholder="HH:mm"
            onChange={(e: any) => {
              let element = {
                target: {
                  value: DateTime.fromJSDate(e[0]).toFormat("HH:mm"),
                },
              };
              formikProps.handleChange(element);
              props.handlers?.onChange(element);
            }}
          />
        </div>
      )}
    </>
  );
};
