import styles from "./AmplifyConfiguration.module.scss";
import { Image, useTheme, View } from "@aws-amplify/ui-react";
import { DefaultComponents } from "@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents";
import { Auth, I18n } from "aws-amplify";
import { Fragment } from "react";
import logo from "@assets/images/simplyhealth-logo-plus-strapline.svg";
import { useRouteMatchWithChildPath } from "../routing/models/route-paths";
import { usePageAnalytics } from "@shared/services/analytics/analytics-hooks";
import { Footer as MainFooter } from "@authenticated/parts/footer/Footer";
import { SignIn } from "../register/pages/SignIn";
import { Button } from "@authenticated/parts/buttons/Button";

const Header = () => {
  const { tokens } = useTheme();
  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image alt="logo" src={logo} padding={tokens.space.medium} />
    </View>
  );
};

const Footer = () => {
  return (
    <div className={styles.footer_wrapper}>
      <MainFooter />
    </div>
  );
};

I18n.putVocabulariesForLanguage("en", {
  "Sign in": "Log in", // Button label
  "Signing in": "Logging in",
  "Resend Code": "Resend email",
});

const originalMessage =
  "PreTokenGeneration invocation failed due to error Socket timeout while invoking Lambda function.";
const translatedMessage = "Login failed. Please try again";

I18n.putVocabularies({
  en: { [originalMessage]: [translatedMessage] },
});

const SignInHeader = () => {
  usePageAnalytics("Login");
  return <SignIn />;
};

const SignInFooter = () => {
  const routePaths = useRouteMatchWithChildPath();
  const pageName = "Login";
  return (
    <Fragment>
      <div className={styles.form_container}>
        <div className={styles.links}>
          <p className={styles.sign_in_footer_header}>Need an online account?</p>
          <p className={styles.info}>
            In order to activate and manage your online account, you will need to
            <Button
              pageName={pageName}
              onClickHandler={() => {
                window.location.href = routePaths.register.path;
              }}
              styling="anchor"
              name="ResendEmail"
              type="button"
            >
              register
            </Button>
            your personal details.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export const services = {
  async handleSignIn(formData: any) {
    let { username, password } = formData;
    username = username.toLowerCase();
    return Auth.signIn({ username, password });
  },
};

export const components: DefaultComponents = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer,
};
