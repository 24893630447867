import logo from '@assets/images/simplyhealth-logo-plus-strapline.svg';
import { ContactUs } from '@authenticated/parts/contact-us/ContactUs';
import styles from './SiteUnavailable.module.scss';

export const SiteUnavailable = () => {
  return (
    <div className={styles.outer_container}>
      <img src={logo} alt="Logo" />
      <h2>Sorry, login is not available</h2>
      <div className={styles.message_text}>
        Sorry, we're down for scheduled maintenance. We'll be back soon, please
        try again later.
      </div>
      <div className={styles.message_text}>
        If you've already booked an online appointment through Spectrum.Life,
        this will go ahead as planned. You'll receive a call within 5 minutes of
        your scheduled appointment time.
      </div>
      <div className={styles.message_text}>
        For <strong>urgent access</strong> to:
      </div>
      <div className={styles.message_text}>
        The GP & nurse service provided by HealthHero, please call{' '}
        <strong>0330 102 5443</strong>
      </div>
      <div className={styles.message_text}>
        In-the-moment mental health services provided by Spectrum.Life, please
        call <strong>0330 102 5445</strong>
      </div>
      <div className={styles.message_text}>
        We apologise for the inconvenience.
      </div>
      <ContactUs pageName="Login" />
    </div>
  );
};
