import signInIllustration from "@assets/images/Key_Password_reset.png";
import errorImage from "@assets/images/error.svg";
import { PageName, PageStatus } from "@authenticated/page-constants";
import { SubmitButton } from "@authenticated/parts/buttons/SubmitButton";
import { FieldElement } from "@authenticated/parts/forms/FieldElement";
import { Spinner } from "@authenticated/parts/spinner/Spinner";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import { Fragment, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useRouteMatchWithChildPath } from "../../routing/models/route-paths";
import styles from "./SignIn.module.scss";
import { initialValues, signInForm, validationSchema } from "./form-meta/signIn.form-config";
import { generateErrorMessage } from "./logic/generateErrorMessage.helper";

export const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState<any>();
  const [stage, setStage] = useState<number>(1);
  const [emailValue, setEmailValue] = useState<string>("");
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loaded");
  const [redirectTo, setRedirectTo] = useState<string>("");
  const PAGE_NAME: PageName = "Login";
  const routePaths = useRouteMatchWithChildPath();

  const handleSubmit = async (values: any) => {
    setPageStatus("Sending");
    const authData = {
      username: values.email.toLowerCase(),
      password: values.password,
    };

    await Auth.signIn(authData)
      .then(async () => {
        setStage(2);
        setPageStatus("Loaded");
      })
      .catch((error) => {
        if (error.message === "User is not confirmed.") {
          setRedirectTo(`${routePaths.emailNotVerified.path}?email=${emailValue}`);
          setPageStatus("Next Page");
        } else {
          setErrorMessage(generateErrorMessage(error.message));
          setPageStatus("Loaded");
        }
      });
  };

  if (stage === 2) {
    window.location.reload();
  }

  return (
    <Fragment>
      {pageStatus === "Next Page" ? (
        <Navigate to={redirectTo} />
      ) : (
        <Fragment>
          {stage === 1 && (
            <div className={styles.form_container}>
              <div className={styles.image_container}>
                <img src={signInIllustration} alt="Sign in Illustration" />
              </div>
              <div>
                <h4 className={styles.header}>Sign in to your account</h4>
                {errorMessage && (
                  <div className={styles.error_message_wrapper} >
                    <img src={errorImage} className={styles.error_image} alt="Error" />
                    <div>
                      <div className={styles.first_line_wrapper}>
                        <p className={styles.error_message} data-testid="error-line-1">
                          <span className={styles.error_message_prefix}>{errorMessage.prefix}</span>{" "}
                          {errorMessage.firstLine}
                        </p>
                      </div>
                      {errorMessage.secondLine && (
                        <p className={`${styles.error_message} ${styles.second_line_wrapper}`} data-testid="error-line-2">
                          {errorMessage.secondLine}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                <div className={styles.info_wrapper}>
                  <p className={styles.sign_in_info}>
                    To access your online account, please sign in by entering your details below.
                  </p>
                </div>
              </div>
              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form>
                  <FieldElement
                    field={signInForm.email}
                    options={{ registerLabel: true, validation: true, pageName: PAGE_NAME }}
                    handlers={{
                      onChange: (e) => {
                        setEmailValue(e.target.value);
                      },
                    }}
                  ></FieldElement>
                  <FieldElement
                    field={signInForm.password}
                    options={{
                      hideErrorMessage: true,
                      registerLabel: true,
                      poundValidation: true,
                      pageName: PAGE_NAME,
                    }}
                  ></FieldElement>
                  <p className={styles.link_wrapper}>
                    <Link className={styles.link} to={routePaths.forgotPassword.path}>
                      Forgot your password?
                    </Link>
                  </p>
                  <SubmitButton
                    buttonClickedText="Signing in..."
                    buttonName="SignIn"
                    buttonText="Sign in"
                    pageName={PAGE_NAME}
                    pageStatus={pageStatus}
                    styling="send"
                  ></SubmitButton>
                </Form>
              </Formik>
            </div>
          )}
          {stage === 2 && (
            <div>
              <Spinner message="Portal loading..." />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
