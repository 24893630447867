import {
  MemberResponse,
  UpdateMemberRequest,
} from '@authenticated/services/models/member-service.interfaces';
import { formatDateForApi } from '@shared/helpers/date-helpers';
import {
  AddressDataModel,
  ContactDetailsDataModel,
  MemberDataModel,
  MemberDetailsModel,
  PreferencesDataModel,
} from '../models/member-details.model';

export const mapMemberResponse = (
  response: MemberResponse,
): MemberDetailsModel => {
  const memberData: MemberDataModel = {
    id: response.data.id,
    firstName: response.data.attributes.firstName,
    middleName: response.data.attributes.middleName,
    lastName: response.data.attributes.lastName,
    dateOfBirth: new Date(response.data.attributes.dateOfBirth),
    gender:
      response.data.attributes.sex === 'FEMALE'
        ? 'Female'
        : response.data.attributes.sex === 'MALE'
          ? 'Male'
          : '',
  };

  const addressData: AddressDataModel = {
    line1: response.data.attributes.contactDetails.address.line1,
    line2: response.data.attributes.contactDetails.address.line2
      ? response.data.attributes.contactDetails.address.line2
      : '',
    line3: response.data.attributes.contactDetails.address.line3
      ? response.data.attributes.contactDetails.address.line3
      : '',
    line4: response.data.attributes.contactDetails.address.line4
      ? response.data.attributes.contactDetails.address.line4
      : '',
    town: response.data.attributes.contactDetails.address.town,
    postcode: response.data.attributes.contactDetails.address.postcode,
    country: response.data.attributes.contactDetails.address.country,
  };

  const contactDetailsData: ContactDetailsDataModel = {
    email: response.data.attributes.contactDetails.emailAddress,
    phoneNumbers: {
      home: response.data.attributes.contactDetails.telephones.home,
      work: response.data.attributes.contactDetails.telephones.other,
      mobile: response.data.attributes.contactDetails.telephones.mobile,
    },
  };

  const preferencesData: PreferencesDataModel = response.data.attributes
    .preferences
    ? {
        servicing: {
          online: response.data.attributes.preferences.servicing
            ? response.data.attributes.preferences.servicing.online
            : true,
          post: response.data.attributes.preferences.servicing
            ? response.data.attributes.preferences.servicing.post
            : false,
        },
        marketing: {
          email: response.data.attributes.preferences.marketing
            ? response.data.attributes.preferences.marketing.email
            : false,
          phone: response.data.attributes.preferences.marketing
            ? response.data.attributes.preferences.marketing.telephone
            : false,
          post: response.data.attributes.preferences.marketing
            ? response.data.attributes.preferences.marketing.post
            : false,
          sms: response.data.attributes.preferences.marketing
            ? response.data.attributes.preferences.marketing.sms
            : false,
        },
      }
    : {
        servicing: {
          online: true,
          post: false,
        },
        marketing: { email: false, phone: false, post: false, sms: false },
      };
  return {
    member: memberData,
    address: addressData,
    contactDetails: contactDetailsData,
    preferences: preferencesData,
  };
};
export const mapSetMemberPreference = (
  memberDetails: MemberDetailsModel,
  values: any,
): any => {
  return {
    emailAddress: memberDetails.contactDetails.email,
    line1: memberDetails.address.line1,
    line2: memberDetails.address.line2,
    line3: memberDetails.address.line3,
    line4: memberDetails.address.line4,
    town: memberDetails.address.town,
    postcode: memberDetails.address.postcode.toUpperCase(),
    country: memberDetails.address.country
      ? memberDetails.address.country
      : 'United Kingdom',
    phoneNumbers: {
      homePhoneNumber: memberDetails.contactDetails.phoneNumbers.home,
      mobilePhoneNumber: memberDetails.contactDetails.phoneNumbers.mobile,
      workPhoneNumber: memberDetails.contactDetails.phoneNumbers.work,
    },
    preferences: {
      servicing: memberDetails.preferences.servicing.online ? 'online' : 'post',
      marketing: {
        email: values.preferences.marketing.email,
        post: values.preferences.marketing.post,
        sms: values.preferences.marketing.sms,
        phone: values.preferences.marketing.phone,
      },
    },
  };
};

export const mapUpdateMemberRequest = (
  memberId: string,
  memberDetails: MemberDetailsModel,
  values: any,
): UpdateMemberRequest => {
  return {
    data: {
      id: memberId,
      type: 'members',
      attributes: {
        firstName: memberDetails.member.firstName,
        middleName: memberDetails.member.middleName,
        lastName: memberDetails.member.lastName,
        dateOfBirth: formatDateForApi(memberDetails.member.dateOfBirth),
        contactDetails: {
          emailAddress: values.emailAddress.trim(),
          address: {
            line1: values.line1,
            line2: values.line2,
            line3: values.line3,
            line4: values.line4,
            town: values.town,
            postcode: values.postcode.toUpperCase(),
            country: memberDetails.address.country
              ? memberDetails.address.country
              : 'United Kingdom',
          },
          telephones: {
            home: values.phoneNumbers.homePhoneNumber,
            mobile: values.phoneNumbers.mobilePhoneNumber,
            other: values.phoneNumbers.workPhoneNumber,
          },
        },
        preferences: {
          servicing: {
            online: values.preferences.servicing === 'online',
            post: values.preferences.servicing === 'post',
          },
          marketing: {
            email: values.preferences.marketing.email === 'true',
            post: values.preferences.marketing.post === 'true',
            sms: values.preferences.marketing.sms === 'true',
            telephone: values.preferences.marketing.phone === 'true',
          },
        },
      },
    },
  };
};
