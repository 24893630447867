import styles from './Spinner.module.scss';

export type SpinnerStyle = 'Tiny' | 'Small' | 'Large';
export interface SpinnerProps {
  message: string;
  size?: SpinnerStyle;
}
export const Spinner = (props: SpinnerProps) => {
  return (
    <div
      className={
        props.size === 'Small'
          ? styles.outer_container_small
          : props.size === 'Tiny'
            ? styles.outer_container_tiny
            : styles.outer_container
      }
      data-testid="spinner-icon">
      <div
        className={
          props.size === 'Tiny'
            ? styles.spinner_container_tiny
            : styles.spinner_container
        }>
        <div className={styles.spinner}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {props.message && <p data-testid="spinner-message">{props.message}</p>}
    </div>
  );
};
