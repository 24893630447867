import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '@assets/images/simplyhealth-logo-plus-strapline.svg';
import { MarketingPreference } from '@authenticated/onboarding/pages/MarketingPreference';
import { Footer } from '@authenticated/parts/footer/Footer';
import { NotFound } from '@authenticated/routing/pages/NotFound';
import { AppStoreLinker } from '@unauthenticated/app-store/AppStoreLinker';
import { ConfirmForgotPassword } from '../forgot-password/pages/ConfirmForgotPassword';
import { ForgotPassword } from '../forgot-password/pages/ForgotPassword';
import { LoggedOut } from '../logged-out/LoggedOut';
import { ConfirmEmail } from '../register/pages/ConfirmEmail';
import { EmailNotVerified } from '../register/pages/EmailNotVerified';
import { Register } from '../register/pages/Register';
import { routePaths } from '../routing/models/route-paths';
import styles from './UnauthenticatedApp.module.scss';

export const UnauthenticatedApp = () => {
  return (
    <Fragment>
      <ToastContainer />
      <div className={styles.outer_container}>
        <div>
          <div slot="header-subtitle" className={styles.image_container}>
            <img src={logo} alt="Company Logo" />
          </div>
          <div className={styles.inner_container}>
            <Routes>
              <Route
                path={routePaths.emailConfirm.path}
                element={<ConfirmEmail />}
              />
              <Route path={routePaths.register.path} element={<Register />} />
              <Route
                path={routePaths.emailNotVerified.path}
                element={<EmailNotVerified />}
              />
              <Route
                path={routePaths.forgotPasswordConfirm.path}
                element={<ConfirmForgotPassword />}
              />
              <Route
                path={routePaths.forgotPassword.path}
                element={<ForgotPassword />}
              />
              <Route path={routePaths.loggedOut.path} element={<LoggedOut />} />
              <Route
                path={routePaths.marketingPreference.path}
                element={<MarketingPreference />}
              />
              <Route
                path={routePaths.appStore.path}
                element={<AppStoreLinker />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};
