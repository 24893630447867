import { Field, useFormikContext } from "formik";

import { createFieldElementDataNameId } from "../helpers/form.helpers";
import { FieldElementProps, getName } from "../FieldElement";
import styles from "../FieldElement.module.scss";

export const SelectLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  return (
    <Field
      as="select"
      className={styles.field}
      data-testid={getName(
        createFieldElementDataNameId(props.field, props.options?.pageName, props.options?.elementTypeName),
        props?.index,
        props.options?.useIndexInName
      )}
      name={props.inputId}
      id={props.inputId}
      onChange={(e: any) => {
        formikProps.handleChange(e);
        props.handlers?.onChange(e);
      }}
    >
      {props.field.items?.map((item: any, index: number) => {
        return (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        );
      })}
    </Field>
  );
};
