import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import errorImage from '@assets/images/error.svg';
import { PageName, PageStatus } from '@authenticated/page-constants';
import { Button } from '@authenticated/parts/buttons/Button';
import { FieldElement } from '@authenticated/parts/forms/FieldElement';
import { routePaths } from '@authenticated/routing/models/route-paths';
import { buildPath } from '@shared/helpers/build-dynamic-path';
import { errorToast, successToast } from '@shared/helpers/toast';
import { usePageAnalytics } from '@shared/services/analytics/analytics-hooks';
import { Layout } from '@unauthenticated/parts/Layout';
import { useRouteMatchWithChildPath } from '@unauthenticated/routing/models/route-paths';
import styles from './ForgotPassword.module.scss';
import {
  forgotPasswordForm,
  initialValues,
  validationSchema,
} from './form-meta/forgot-password.form-config';

export const ForgotPassword = () => {
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [pageStatus, setPageStatus] = useState<PageStatus>('Loaded');
  const [pageName, setPageName] = useState<PageName>('ForgotPassword');
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [origin, setOrigin] = useState<string | null>(null);

  const unauthenticatedRoutePaths = useRouteMatchWithChildPath();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (stage === 2) {
      setPageName('ForgotPasswordConfirmation');
    }
  }, [stage]);

  useEffect(() => {
    if (searchParams) {
      const origin = searchParams.get('origin');
      !!origin && setOrigin(origin);
    }
  }, [searchParams]);

  useEffect(() => {
    if (error === unverifiedError) {
      setRedirectTo(
        `${unauthenticatedRoutePaths.emailNotVerified.path}?email=${emailValue}&page=forgotPassword`,
      );
      setPageStatus('Next Page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, emailValue]);

  usePageAnalytics(pageName);

  let clientMetadata;

  if (origin) {
    clientMetadata = { origin };
  }

  const handleSend = (values: any) => {
    Auth.forgotPassword(values.email.trim().toLowerCase(), clientMetadata)
      .then(() => {
        setEmail(values.email);
        setStage(2);
      })
      .catch(err => {
        setError(err.message);
        errorToast(
          err.message === validationError
            ? 'Your email address should be a valid email format.'
            : err.message === unverifiedError
              ? 'Email not verified'
              : err.message === userNotFoundError
                ? 'Email not found'
                : err.message,
          pageName,
          'Action required!',
        );
      });
  };

  const unverifiedError =
    'Cannot reset password for the user as there is no registered/verified email or phone_number';
  const userNotFoundError = 'Username/client id combination not found.';
  const validationError =
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";

  const resendEmail = () => {
    Auth.forgotPassword(email, clientMetadata)
      .then(() => {
        successToast('Email sent', pageName);
      })
      .catch(err => {
        errorToast(err.message, pageName);
      });
  };

  return (
    <Fragment>
      {pageStatus === 'Next Page' ? (
        <Navigate to={redirectTo} />
      ) : (
        <>
          {stage === 1 ? (
            <Layout
              title="Password assistance"
              imageSource="Key_Password_reset.png"
              imageAltText="Forgot_Password Illustration">
              {error === userNotFoundError && (
                <div className={styles.error_message_wrapper}>
                  <img
                    src={errorImage}
                    className={styles.error_image}
                    alt="Error"
                  />
                  <div>
                    <div className={styles.first_line_wrapper}>
                      <p className={styles.error_message}>
                        <span className={styles.error_message_prefix}>
                          Is this the right email ?{' '}
                        </span>
                        We can't match this email to your account
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <p
                className={styles.forgot_password_info}
                data-testid="first-para">
                {error === userNotFoundError
                  ? "To reset your password, make sure you're using the email you registered with. This could be an old/different email address to the one you've just tried."
                  : "If you have forgotten your password, enter the email address you use to access your account and we'll email you a link to reset your password."}
              </p>
              <p
                className={styles.forgot_password_info}
                data-testid="second-para">
                {error === userNotFoundError
                  ? "Once we've matched your email to your online account we'll send you an email with a link to reset your password. This link will expire after one hour."
                  : "Once requested, if you have not received an email within the next few minutes check your 'junk' or 'spam' email folder."}
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSend}>
                <Form>
                  <FieldElement
                    field={forgotPasswordForm.email}
                    options={{
                      registerLabel: true,
                      validation: true,
                      pageName: pageName,
                    }}
                    handlers={{
                      onChange: e => {
                        setEmailValue(e.target.value);
                      },
                    }}
                  />
                  <Button
                    name="SendResetLink"
                    pageName={pageName}
                    styling="send"
                    type="submit">
                    Request password reset
                  </Button>
                  <div>
                    <p className={styles.link_wrapper}>
                      {error === userNotFoundError
                        ? "Hurray - I've just remembered my password -"
                        : 'I just remembered my password -'}
                      <span
                        className={styles.link}
                        onClick={() => {
                          if (!!origin) {
                            const decodedOrigin = atob(origin);
                            window.location.href =
                              window.location.origin + decodedOrigin;
                          } else {
                            setRedirectTo(buildPath(routePaths.home, []));
                            setPageStatus('Next Page');
                          }
                        }}>
                        Sign In
                      </span>
                    </p>
                  </div>
                </Form>
              </Formik>
            </Layout>
          ) : (
            <Layout
              title="Ok great!"
              titleSecondLine="Now check your email..."
              imageSource="Emails_AppIllustrations.png"
              imageAltText="Successful">
              <p className={styles.text}>
                We've sent an email to{' '}
                <span className={styles.email}>{email}</span>
              </p>
              <p className={styles.info}>
                Within it, you'll find instructions to verify your email
                account. Note: the link will expire within 1 hour.
              </p>
              <div>
                <p className={`${styles.text} ${styles.second_text_wrapper}`}>
                  Not received email?
                </p>
                <p className={styles.resend_button_wrapper}>
                  Remember to check your spam and junk folder.
                  <Button
                    name="ResendEmail"
                    pageName={pageName}
                    type="button"
                    styling="anchor"
                    onClickHandler={resendEmail}>
                    Resend email
                  </Button>
                </p>
              </div>
            </Layout>
          )}
        </>
      )}
    </Fragment>
  );
};
