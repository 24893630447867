import * as Yup from "yup";
import { FieldMeta } from "@authenticated/parts/forms/helpers/form.helpers";
import { validationMessagesFor } from "@shared/helpers/validation-message.helpers";

const emailMessages = validationMessagesFor("Your email address");

export interface ForgotPasswordFormConfig {
  email: FieldMeta;
}

export const initialValues = {
  email: "",
};

export const forgotPasswordForm: ForgotPasswordFormConfig = {
  email: {
    name: "email",
    label: "Your email",
    type: "email",
    placeholderText: "Enter your email address",
    schema: Yup.string().required(emailMessages.required()).email(emailMessages.email()),
  },
};

export const validationSchema = Yup.object({
  [forgotPasswordForm.email.name]: forgotPasswordForm.email.schema,
});
