import { Field, useFormikContext } from "formik";

import { FieldElementProps, getName, getPlaceholder } from "../FieldElement";
import { createFieldElementDataNameId } from "../helpers/form.helpers";
import styles from "../FieldElement.module.scss";

export const PriceFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();

  return (
    <div className={styles.price_wrapper}>
      <div className={styles.price}>{props.prefix}</div>
      <Field
        className={styles.price_field}
        data-testid={getName(
          createFieldElementDataNameId(props.field, props.options?.pageName, props.options?.elementTypeName),
          props?.index,
          props.options?.useIndexInName
        )}
        type="text"
        inputMode="decimal"
        id={props.inputId}
        placeholder={getPlaceholder(props.field.type, props.field.placeholderText)}
        name={props.inputId}
        onChange={(e: any) => {
          formikProps.handleChange(e);
          props.handlers?.onChange(e);
        }}
        min={props.field.range?.min}
        max={props.field.range?.max}
        step={props.field.range?.step}
      />
    </div>
  );
};
