import { DateTime } from "luxon";

const dateLocale = "en-GB";
const ordinals = ["th", "st", "nd", "rd"];
export const lowDate = new Date("1900-01-01T00:00:00Z");
export const lowDateString = DateTime.now().minus({ years: 7 }).toISODate() || "";
export const nowDateString = DateTime.now().toISODate() || "";

export const formatDateForDisplay = (inputDate: Date): any => {
  return inputDate === lowDate ? "" : DateTime.fromJSDate(inputDate, { zone: "utc" }).toISODate();
};

export const formatDateForApi = (inputDate: Date): any => {
  return inputDate === lowDate ? "" : DateTime.fromJSDate(inputDate).toISODate();
};

export const formatDateForMessage = (inputDate?: Date): string => {
  if (inputDate) {
    return inputDate === lowDate
      ? ""
      : DateTime.fromJSDate(new Date(inputDate)).setLocale(dateLocale).toLocaleString(DateTime.DATE_SHORT);
  } else {
    return "";
  }
};

export const formatISODateForDisplay = (inputDate: string): string => {
  return DateTime.fromISO(inputDate).setLocale(dateLocale).toLocaleString();
};

export const formatLongDate = (inputDate: Date): string => {
  return inputDate === lowDate
    ? ""
    : addOrdinal(DateTime.fromJSDate(inputDate).setLocale(dateLocale).toFormat("d MMMM y"));
};

export const formatLongDateDDMM = (inputDate: Date): string => {
  return inputDate === lowDate
    ? ""
    : addOrdinal(DateTime.fromJSDate(inputDate).setLocale(dateLocale).toFormat("d MMMM"));
}

export const formatTimestamp = (inputDate: Date, inputTime: string): string => {
  const splitTime = inputTime.split(":");
  let timestamp: any = DateTime.fromJSDate(new Date(inputDate), { zone: "utc" }).set({
    hour: +splitTime[0],
    minute: +splitTime[1],
  });
  return timestamp.toISO();
};

export const deltaDate = (input: Date, days: number, months: number, years: number): Date => {
  return DateTime.fromJSDate(input).plus({ years: years, months: months, days: days }).toJSDate();
};

export const parseInputDate = (inputDate: string): Date | undefined => {
  const parts = inputDate.match(/(\d+)/g);
  if (parts && parts.length === 3) {
    const year = inputDate.indexOf("-") !== -1 ? parts[0] : parts[2];
    const month = parts[1];
    const day = inputDate.indexOf("-") !== -1 ? parts[2] : parts[0];
    if (+year > 2000) {
      const parsedDate = DateTime.utc(+year, +month, +day);
      return parsedDate.toJSDate();
    }
  }
  return undefined;
};

export const getAgeInYears = (dateOfBirth: Date): number => {
  return Math.abs(DateTime.fromJSDate(new Date(dateOfBirth)).diffNow().as("years"));
};

const addOrdinal = (longDate: string): string => {
  const spacePos = longDate.indexOf(" ");
  const day = +longDate.slice(0, spacePos);
  const value = day % 100;
  const ordinal = ordinals[(value - 20) % 10] || ordinals[value] || ordinals[0];
  return day + ordinal + longDate.slice(spacePos);
};
