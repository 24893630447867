import { ToastOptions, toast } from "react-toastify";
import { PageName } from "@authenticated/page-constants";

const baseProperties: ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "colored",
};

export const successToast = (message: string, pageName: PageName, title?: string) => {
  toast.success(
    <div>
      <div>{title === undefined ? "Success!" : title}</div>
      <div>{message}</div>
    </div>,
    { ...baseProperties, toastId: pageName }
  );
};

export const errorToast = (message: string, pageName: PageName, prefix?: string) => {
  toast.error(
    <div>
      <div>{prefix ? prefix : "Error!"}</div>
      <div>{message}</div>
    </div>,
    { ...baseProperties, toastId: pageName }
  );
};
