export const createElementDataNameId = (
  elementName: string,
  pageName: string,
  elementType: string,
  postFix?: string
): string => {
  let dataName = `${pageName}_${elementName}_${elementType}`;
  if (postFix) {
    dataName = `${dataName}_${postFix}`;
  }
  return dataName;
};
