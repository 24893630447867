import { Field, useFormikContext } from "formik";

import { FieldElementProps, getName, getPlaceholder } from "../FieldElement";
import { createFieldElementDataNameId } from "../helpers/form.helpers";
import styles from "../FieldElement.module.scss";

export const CheckboxFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  return (
    <Field
      className={styles.checkbox}
      data-testid={getName(
        createFieldElementDataNameId(props.field, props.options?.pageName, props.options?.elementTypeName),
        props?.index,
        props.options?.useIndexInName
      )}
      type={props.field.type}
      id={props.inputId}
      placeholder={getPlaceholder(props.field.type, props.field.placeholderText)}
      name={props.inputId}
      onChange={(e: any) => {
        formikProps.handleChange(e);
        props.handlers?.onChange(e);
      }}
    />
  );
};
