import * as Yup from "yup";
import { FieldMeta } from "@authenticated/parts/forms/helpers/form.helpers";
import { maxPasswordLength, minPasswordLength } from "@shared/constants/site-constants";
import { validationMessagesFor } from "@shared/helpers/validation-message.helpers";
import * as RE from "@shared/helpers/regular-expressions";

const passwordMessages = validationMessagesFor("Password");
const confirmPasswordMessages = validationMessagesFor("Confirm password");

export interface ConfirmForgotPasswordFormConfig {
  password: FieldMeta;
  confirmPassword: FieldMeta;
}

export const initialValues = {
  password: "",
  confirmPassword: "",
};

export const confirmForgotPasswordForm: ConfirmForgotPasswordFormConfig = {
  password: {
    name: "password",
    label: "New password",
    type: "password",
    placeholderText: "Enter your password",
    schema: Yup.string().required(passwordMessages.required()),
  },
  confirmPassword: {
    name: "confirmPassword",
    label: "Confirm new password",
    type: "password",
    placeholderText: "Enter your password",
    schema: Yup.string().required(confirmPasswordMessages.required()).min(minPasswordLength, passwordMessages.minLength(minPasswordLength))
      .max(maxPasswordLength, passwordMessages.maxLength(maxPasswordLength))
      .matches(RE.password, passwordMessages.invalid()).oneOf([Yup.ref("password"), ""], "Passwords must match"),
  },
};

export const validationSchema = Yup.object({
  [confirmForgotPasswordForm.password.name]: confirmForgotPasswordForm.password.schema,
  [confirmForgotPasswordForm.confirmPassword.name]: confirmForgotPasswordForm.confirmPassword.schema,
});
