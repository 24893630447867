import backArrow from '@assets/images/arrow-dark.svg';
import fileImage from '@assets/images/choose-file.svg';
import cross from '@assets/images/cross.svg';
import description from '@assets/images/description.svg';
import openNewTabImage from '@assets/images/open-new-tab.svg';
import makeCallImage from '@assets/images/phone-icon.svg';
import arrowBlue from '@assets/images/right-arrow-blue.svg';
import arrow from '@assets/images/right-arrow.svg';
import { createElementDataNameId } from '@shared/helpers/create-element-data-name-id';
import { MouseEventHandler, ReactNode, useMemo } from 'react';
import { PageName } from '../../page-constants';
import styles from './Button.module.scss';
export type ButtonType = 'button' | 'reset' | 'submit';
export type ButtonStyling =
  | 'anchor'
  | 'as-text'
  | 'back'
  | 'bank_details'
  | 'blue_arrow'
  | 'choose-file'
  | 'download'
  | 'make-call'
  | 'new-primary'
  | 'new-secondary'
  | 'open-new-tab'
  | 'primary'
  | 'secondary'
  | 'secondary-block'
  | 'secondary-remove'
  | 'secondary-turquoise'
  | 'send';

export interface ButtonProps {
  children: ReactNode;
  styling: ButtonStyling;
  pageName: PageName;
  name: string;
  type: ButtonType;
  onClickHandler?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
  value?: string;
  disabled?: boolean;
}

export const Button = (props: ButtonProps) => {
  const className = useMemo(() => {
    switch (props.styling) {
      case 'anchor':
        return styles.anchor_button;
      case 'as-text':
        return styles.as_text;
      case 'back':
        return styles.back_button;
      case 'bank_details':
        return styles.bank_details;
      case 'blue_arrow':
        return styles.blue_arrow;
      case 'choose-file':
        return styles.choose_file_button;
      case 'download':
        return styles.download_button;
      case 'make-call':
        return styles.make_call_button;
      case 'new-primary':
        return styles.new_primary_button;
      case 'new-secondary':
        return styles.new_secondary_button;
      case 'open-new-tab':
        return styles.open_new_tab_button;
      case 'primary':
        return styles.primary_button;
      case 'secondary-block':
        return styles.secondary_block_button;
      case 'secondary-remove':
        return styles.secondary_remove_button;
      case 'secondary-turquoise':
        return styles.secondary_button_turquoise;
      case 'send':
        return styles.send_button;
      default:
        return styles.secondary_button;
    }
  }, [props.styling]);

  return (
    <button
      value={props.value}
      type={props.type}
      data-testid={createElementDataNameId(
        props.name,
        props.pageName,
        'Button',
      )}
      className={className}
      onClick={props.onClickHandler}
      disabled={props.disabled}
      id={props.id}>
      {props.styling === 'choose-file' && (
        <img
          className={styles.choose_file_image}
          src={fileImage}
          alt="Arrow"
          id={props.value}
        />
      )}
      {props.styling === 'download' && (
        <img
          className={styles.image}
          src={description}
          alt="View"
          id={props.value}
        />
      )}
      {props.styling === 'back' && (
        <img
          className={styles.arrow_back_image}
          src={backArrow}
          alt="BackArrow"
          id={props.value}
        />
      )}
      {props.styling === 'secondary-remove' && (
        <img
          className={styles.cross_image}
          src={cross}
          alt="cross"
          id={props.value}
        />
      )}
      {props.children}
      {props.styling === 'send' && (
        <img
          className={styles.arrow_image}
          src={arrow}
          alt="Arrow"
          id={props.value}
        />
      )}
      {props.styling === 'open-new-tab' && (
        <img
          className={styles.open_new_tab_image}
          src={openNewTabImage}
          alt="new tab"
          id={props.value}
        />
      )}
      {props.styling === 'make-call' && (
        <img
          className={styles.make_call_image}
          src={makeCallImage}
          alt="make call"
          id={props.value}
        />
      )}
      {props.styling === 'blue_arrow' && (
        <img
          className={styles.blue_arrow_image}
          src={arrowBlue}
          alt="Arrow"
          id={props.value}
        />
      )}
    </button>
  );
};
