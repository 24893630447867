import * as Yup from "yup";
import { FieldMeta } from "@authenticated/parts/forms/helpers/form.helpers";
import { maxPasswordLength, minPasswordLength } from "@shared/constants/site-constants";
import { validationMessagesFor } from "@shared/helpers/validation-message.helpers";
import * as RE from "@shared/helpers/regular-expressions";

const emailMessages = validationMessagesFor("Email address");
const confirmEmailMessages = validationMessagesFor("Email address");
const passwordMessages = validationMessagesFor("Password");
const confirmPasswordMessages = validationMessagesFor("Confirm password");

export interface RegisterFormConfig {
  email: FieldMeta;
  confirmEmail: FieldMeta;
  password: FieldMeta;
  confirmPassword: FieldMeta;
}

export const initialValues = {
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: "",
};

export const registerForm: RegisterFormConfig = {
  email: {
    name: "email",
    label: "Your email",
    type: "email",
    placeholderText: "Enter your email address",
    schema: Yup.string().required(emailMessages.required()).email(emailMessages.emailFormat()),
  },
  confirmEmail: {
    name: "confirmEmail",
    label: "Confirm your email",
    type: "email",
    placeholderText: "Confirm your email address",
    schema: Yup.string().required(confirmEmailMessages.required()).email(confirmEmailMessages.emailFormat())
      .oneOf([Yup.ref("email"), ""], "Email addresses do not match"),
  },
  password: {
    name: "password",
    label: "Create password",
    type: "password",
    placeholderText: "Enter your password",
    schema: Yup.string().required(passwordMessages.required()),
  },
  confirmPassword: {
    name: "confirmPassword",
    label: "Confirm your password",
    type: "password",
    placeholderText: "Confirm your password",
    schema: Yup.string().required(confirmPasswordMessages.required()).min(minPasswordLength, passwordMessages.minLength(minPasswordLength))
      .max(maxPasswordLength, passwordMessages.maxLength(maxPasswordLength))
      .matches(RE.password, passwordMessages.invalid()).oneOf([Yup.ref("password"), ""], "Passwords do not match"),
  },
};

export const validationSchema = Yup.object({
  [registerForm.email.name]: registerForm.email.schema,
  [registerForm.confirmEmail.name]: registerForm.confirmEmail.schema,
  [registerForm.password.name]: registerForm.password.schema,
  [registerForm.confirmPassword.name]: registerForm.confirmPassword.schema,
});
