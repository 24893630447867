import {
  AddAccessControlAction,
  AddClaimDataAction,
  AddDentalAccidentDataAction,
  AddMemberBenefitsAction,
  AddMemberIdsAction,
  AddMemberTypeAction,
  AddModalToDisplay,
  AddReceiptsAction,
  ChangeEmailVerifiedAction,
  ChangeLoginRecordedAction,
  ChangeMemberLoadedAction,
  ChangeUpdatesAvailableAction,
  StateActions,
} from '../actions/actions';
import { AppStateModel } from '../models/app-state.model';

const saveState = (state: AppStateModel): AppStateModel => {
  //writeToStorage("App_State", state);
  return state;
};

export const appStateReducer = (
  state: AppStateModel,
  action: StateActions,
): AppStateModel => {
  switch (action.type) {
    case 'Add Member Ids': {
      const addMemberIdsAction = action as AddMemberIdsAction;
      return saveState({
        ...state,
        memberIds: addMemberIdsAction.memberIds,
      });
    }
    case 'Add Member Benefits': {
      const addMemberBenefitsAction = action as AddMemberBenefitsAction;
      return saveState({
        ...state,
        memberBenefits: addMemberBenefitsAction.memberBenefits,
      });
    }
    case 'Add Member Type': {
      const addMemberTypeAction = action as AddMemberTypeAction;
      return saveState({
        ...state,
        memberType: addMemberTypeAction.memberType,
      });
    }
    case 'Change Login Recorded': {
      const changeLoginRecordedAction = action as ChangeLoginRecordedAction;
      return saveState({
        ...state,
        loginRecorded: changeLoginRecordedAction.loginRecorded,
      });
    }
    case 'Change Member Loaded': {
      const changeMemberLoadedAction = action as ChangeMemberLoadedAction;
      return saveState({
        ...state,
        memberLoaded: changeMemberLoadedAction.memberLoaded,
      });
    }
    case 'Change Email Verified': {
      const changeEmailVerifiedAction = action as ChangeEmailVerifiedAction;
      return saveState({
        ...state,
        emailVerified: changeEmailVerifiedAction.emailVerified,
      });
    }
    case 'Change Updates Available': {
      const changeUpdatesAvailableAction =
        action as ChangeUpdatesAvailableAction;
      return saveState({
        ...state,
        updatesAvailable: changeUpdatesAvailableAction.updatesAvailable,
      });
    }
    case 'Add Claim Data': {
      const addClaimDataAction = action as AddClaimDataAction;
      return saveState({
        ...state,
        makeAClaim: {
          ...state.makeAClaim,
          claim: addClaimDataAction.claim,
        },
      });
    }
    case 'Add Receipts': {
      const addReceiptsAction = action as AddReceiptsAction;
      return saveState({
        ...state,
        makeAClaim: {
          ...state.makeAClaim,
          receipts: addReceiptsAction.receipts,
        },
      });
    }
    case 'Add Dental Accident Data': {
      const addDentalAccidentDataAction = action as AddDentalAccidentDataAction;
      return saveState({
        ...state,
        makeAClaim: {
          ...state.makeAClaim,
          dentalAccident: addDentalAccidentDataAction.dentalAccident,
        },
      });
    }
    case 'Add Access Control': {
      const addAccessControlAction = action as AddAccessControlAction;
      return saveState({
        ...state,
        accessControl: addAccessControlAction.accessControl,
      });
    }
    case 'Add Modal To Display': {
      const addModalToDisplayAction = action as AddModalToDisplay;
      return saveState({
        ...state,
        modalToDisplay: addModalToDisplayAction.modalToDisplay,
      });
    }
    default: {
      return state;
    }
  }
};
