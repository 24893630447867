import { PageName } from '@authenticated/page-constants';

type Channel = 'Claims portal' | 'Log in';

export type AnalyticsPageModel = {
  [key in PageName]: {
    pageName: string;
    channel: Channel;
  };
};

export const analyticsPageNames: AnalyticsPageModel = {
  Home: { pageName: 'Homepage', channel: 'Claims portal' },
  ViewClaims: { pageName: 'View claims', channel: 'Claims portal' },
  MakeClaimReceipts: {
    pageName: 'Make a claim New OCR: Step 1 Receipts',
    channel: 'Claims portal',
  },
  MakeClaimReceiptsUploaded: {
    pageName: 'Make a claim New OCR: Step 1 Receipts uploaded',
    channel: 'Claims portal',
  },
  MakeClaimScanning: {
    pageName: 'Make a claim New OCR: Step 2 Scanning',
    channel: 'Claims portal',
  },
  MakeClaimDetails: {
    pageName: 'Make a claim New OCR: Step 3 Review details',
    channel: 'Claims portal',
  },
  MakeClaimConfirmation: {
    pageName: 'Make a claim New OCR: Step 4 Claim submission confirmation',
    channel: 'Claims portal',
  },
  MakeClaimDentalAccident: {
    pageName: 'Make a claim: Dental accident',
    channel: 'Claims portal',
  },
  ClaimsUnavailable: {
    pageName: 'Claims unavailable',
    channel: 'Claims portal',
  },
  ViewReceipt: { pageName: 'View receipt', channel: 'Claims portal' },
  ViewDocuments: { pageName: 'View documents', channel: 'Claims portal' },
  ViewDocument: { pageName: 'View document', channel: 'Claims portal' },
  Policies: { pageName: 'Policies', channel: 'Claims portal' },
  InviteDependant: { pageName: 'Invite dependant', channel: 'Claims portal' },
  AddBankDetails: { pageName: 'Add bank details', channel: 'Claims portal' },
  ViewBankDetails: { pageName: 'View bank details', channel: 'Claims portal' },
  Wellbeing: { pageName: 'myWellbeing', channel: 'Claims portal' },
  EditBankDetails: { pageName: 'Edit bank details', channel: 'Claims portal' },
  ClaimFurtherInformation: {
    pageName: 'Make a claim : Further information',
    channel: 'Claims portal',
  },
  Global: { pageName: '', channel: 'Claims portal' },
  ChangePolicy: { pageName: 'Upgrade policy', channel: 'Claims portal' },
  ViewPersonalDetails: {
    pageName: 'View personal details',
    channel: 'Claims portal',
  },
  EditPersonalDetails: {
    pageName: 'Edit personal details',
    channel: 'Claims portal',
  },
  ViewClaimDetails: {
    pageName: 'View claim details',
    channel: 'Claims portal',
  },
  SecurityDetails: { pageName: 'Security details', channel: 'Claims portal' },
  NotFound: { pageName: 'Page not found', channel: 'Claims portal' },
  GPService: { pageName: 'GPService', channel: 'Claims portal' },
  Signout: { pageName: 'Sign out', channel: 'Claims portal' },
  Register: { pageName: 'Create account', channel: 'Log in' },
  RegisterEmailNotVerified: {
    pageName: 'Email Not Verifiedt',
    channel: 'Log in',
  },
  RegisterConfirmation: {
    pageName: 'Create account confirmation',
    channel: 'Log in',
  },
  RegisterConfirmEmail: { pageName: 'Confirm email', channel: 'Log in' },
  ForgotPassword: { pageName: 'Forgotten password', channel: 'Log in' },
  ForgotPasswordConfirmation: {
    pageName: 'Forgotten password confirmation',
    channel: 'Log in',
  },
  ForgotPasswordNewPassword: {
    pageName: 'Create new password',
    channel: 'Log in',
  },
  ForgotPasswordNewPasswordConfirmation: {
    pageName: 'Create new password confirmation',
    channel: 'Log in',
  },
  OnboardingAdditionalDetails: {
    pageName: 'Additional details',
    channel: 'Log in',
  },
  OnboardingPersonalDetails: {
    pageName: 'Personal details',
    channel: 'Log in',
  },
  OnboardingError: { pageName: 'Error page', channel: 'Log in' },
  OnboardingDuplicateAccount: {
    pageName: 'Duplicate account',
    channel: 'Log in',
  },
  Login: { pageName: 'Sign in', channel: 'Log in' },
  OnboardingLoggedOut: { pageName: 'Logged out', channel: 'Log in' },
  OnboardingMatched: { pageName: 'Matched', channel: 'Log in' },
  UnsupportedBrowser: { pageName: 'UnsupportedBrowser', channel: 'Log in' },
  AppStoreLinks: { pageName: 'App store links', channel: 'Log in' },
  OnboardingMarketingPreferences: {
    pageName: 'OnboardingMarketingPreferences',
    channel: 'Log in',
  },
  PhioService: { pageName: 'PhioService', channel: 'Claims portal' },
  PartnerList: { pageName: 'Partner list', channel: 'Claims portal' },
  MyHealth: { pageName: 'MyHealth', channel: 'Claims portal' },
  SymptomChecker: { pageName: 'Symptom Checker', channel: 'Claims portal' },
  HowCanWeHelp: { pageName: 'How can we help', channel: 'Claims portal' },
};
