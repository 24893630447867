import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";

import { Layout } from "@unauthenticated/parts/Layout";
import errorImage from "@assets/images/error.svg";
import { Button } from "@authenticated/parts/buttons/Button";
import { errorToast, successToast } from "@shared/helpers/toast";
import { generateErrorMessage } from "./logic/generateErrorMessage.helper";
import styles from "./EmailNotVerified.module.scss";

export const EmailNotVerified = () => {
  const [username, setUsername] = useState<any | null>("");
  const [errorMessage, setErrorMessage] = useState<any>({ prefix: "" });
  const [page, setPage] = useState<string>("default");

  const pageName = "RegisterEmailNotVerified";
  const search = useLocation().search;

  useEffect(() => {
    const id = new URLSearchParams(search).get("email");
    const page = new URLSearchParams(search).get("page");
    setUsername(id);
    setErrorMessage(generateErrorMessage(page === "forgotPassword" ? page : "User unverified error"));
    setPage(page === "forgotPassword" ? page : "default");
  }, [search]);

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(username);
      successToast("Email resent successfully", pageName);
    } catch (err: any) {
      errorToast(err.message, pageName);
    }
  };

  return (
    <Layout title="Email account not verified" imageSource="Emails_AppIllustrations.png" imageAltText="Error">
      <div className={styles.error_message_wrapper}>
        <img src={errorImage} className={styles.error_image} alt="Error" />
        <div>
          <div className={styles.first_line_wrapper}>
            <p className={styles.error_message}>
              <span className={styles.error_message_prefix}>{errorMessage.prefix}</span>
              {errorMessage.firstLine}
            </p>
          </div>
          {errorMessage.secondLine && (
            <p className={`${styles.error_message} ${styles.second_line_wrapper}`}>{errorMessage.secondLine}</p>
          )}
        </div>
      </div>
      <p className={styles.info}>
        {page === "default"
          ? "Before you can sign in to your account, you will need to find the verification email we sent you."
          : "This won't take a minute, just find the verification email we sent you and follow the instructions to verify your email account."}
      </p>
      <p className={styles.info}>
        {page === "default"
          ? "Within it, you'll find instructions to verify your email account. Note: The link will expire within 24 hours."
          : "The link will expire after 24 hours. Click resend email link below if you missed this window."}
      </p>
      <div className={styles.links}>
        <p className={styles.footer_title}>{page === "default" ? "Not received an email?" : "Can't find your email?"}</p>
        <p className={styles.footer_info}>
          Remember to check your spam and junk folder.
          <Button pageName={pageName} onClickHandler={resendCode} styling="anchor" name="ResendEmail" type="button">
            Resend email.
          </Button>
        </p>
      </div>
    </Layout>
  );
};
