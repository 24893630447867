import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import {
  regexName,
  regexPolicyNumber,
  regexPostcode,
} from '../../../../shared/helpers/regex';
import { FieldElementProps, getName, getPlaceholder } from '../FieldElement';
import styles from '../FieldElement.module.scss';
import { createFieldElementDataNameId } from '../helpers/form.helpers';

export const InputFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  const [value, setValue] = useState<string>('');
  let validationSchema =
    props.options?.validationSchema === 'postcode'
      ? regexPostcode.test(value)
      : props.options?.validationSchema === 'policyNumber'
        ? regexPolicyNumber.test(value)
        : regexName.test(value);
  let validation =
    value === '' ? null : validationSchema ? styles.success : styles.error;
  let { lastName }: any = formikProps.values;
  useEffect(() => {
    if (props.inputId === 'lastName') {
      setValue(lastName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Field
      className={`${styles.field} ${props.field.style === 'large' && styles.large} ${
        props.options?.validation && validation
      }`}
      data-testid={getName(
        createFieldElementDataNameId(
          props.field,
          props.options?.pageName,
          props.options?.elementTypeName,
        ),
        props?.index,
        props.options?.useIndexInName,
      )}
      type={props.field.type}
      id={props.inputId}
      placeholder={getPlaceholder(
        props.field.type,
        props.field.placeholderText,
      )}
      name={props.inputId}
      onChange={(e: any) => {
        setValue(e.target.value.trim());
        formikProps.handleChange(e);
        props.handlers?.onChange(e);
      }}
      min={props.field.range?.min}
      max={props.field.range?.max}
      step={props.field.range?.step}
    />
  );
};
