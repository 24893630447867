import {
  COUNSELLING_CONS_CORP_VOL,
  COUNSELLING_CORP_F2F,
  COUNSELLING_CORP_NON_F2F,
  EAP_BENEFITS,
  GP_BENEFITS,
} from '@shared/constants/benefit-constants';
import { DENPLAN_PLUS_PRODUCTS } from '@shared/constants/product-constants';
import {
  AccessControl,
  createInitialAccessControl,
} from '../models/access-control.models';
import { MemberPolicyListModel } from '../models/policy-list.models';
import { GetUserResponse } from '../models/user.interfaces';

export const mapAccessControl = (
  userData: GetUserResponse,
  memberBenefits: Array<string>,
  memberPolicies: MemberPolicyListModel,
): AccessControl => {
  const accessControl = createInitialAccessControl();

  if (memberBenefits.some(e => GP_BENEFITS.includes(e))) {
    accessControl.hasGPBenefit = true;
    accessControl.hasHealthPlan = true;
  }

  if (accessControl.hasGPBenefit) {
    const filteredBenefits = memberBenefits.filter(benefit => {
      return !GP_BENEFITS.includes(benefit) && !EAP_BENEFITS.includes(benefit);
    });
    if (!filteredBenefits || filteredBenefits.length === 0) {
      accessControl.hasGPOnly = true;
    }
  }

  if (
    memberPolicies &&
    memberPolicies.policies &&
    memberPolicies.policies.length > 0
  ) {
    accessControl.hasPolicies = true;
    accessControl.hasActivePolicies = memberPolicies.policies.some(
      policy => policy.status === 'Active',
    );
  } else if (memberBenefits && memberBenefits.length > 0) {
    accessControl.isDependantOnly = true;
    accessControl.hasPolicies = true;
    accessControl.hasActivePolicies = true;
  }

  if (
    (userData['simplyhealth:dhp_member_ids'] || []).length > 0 &&
    userData['simplyhealth:pulse_member_ids'].length === 0 &&
    (userData['simplyhealth:ski_member_ids'] || []).length === 0
  ) {
    accessControl.isDhpMemberOnly = true;
  }

  if (
    memberPolicies.policies.some(e =>
      DENPLAN_PLUS_PRODUCTS.includes(e.product.id),
    )
  ) {
    accessControl.counsellingType = 'Denplan Plus';
  } else if (memberBenefits.some(e => COUNSELLING_CONS_CORP_VOL.includes(e))) {
    accessControl.counsellingType = 'Consumer/CorpVol';
  } else if (memberBenefits.some(e => COUNSELLING_CORP_F2F.includes(e))) {
    accessControl.counsellingType = 'Corporate F2F';
  } else if (memberBenefits.some(e => COUNSELLING_CORP_NON_F2F.includes(e))) {
    accessControl.counsellingType = 'Corporate Non F2F';
  }
  if (memberPolicies.policies.some(policy => policy.status === 'Active')) {
    accessControl.hasActivePolicies = true;
  }
  return accessControl;
};
