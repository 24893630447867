import { lowDate } from "@shared/helpers/date-helpers";

export interface MemberDetailsModel {
  member: MemberDataModel;
  address: AddressDataModel;
  contactDetails: ContactDetailsDataModel;
  preferences: PreferencesDataModel;
}

export interface MemberDataModel {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: string;
}

export interface AddressDataModel {
  line1: string;
  line2?: string;
  line3?: string;
  line4?: string;
  town: string;
  postcode: string;
  country: string;
}

export interface ContactDetailsDataModel {
  email: string;
  phoneNumbers: {
    home: string;
    work: string;
    mobile: string;
  };
}

export interface PreferencesDataModel {
  servicing: {
    post: boolean;
    online: boolean;
  };
  marketing: {
    post: boolean;
    email: boolean;
    phone: boolean;
    sms: boolean;
  };
}

export const getInitialMemberDetailsModel = (): MemberDetailsModel => {
  return {
    member: {
      id: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: lowDate,
      gender: "",
    },
    address: {
      line1: "",
      postcode: "",
      town: "",
      country: "",
    },
    contactDetails: {
      email: "",
      phoneNumbers: {
        home: "",
        mobile: "",
        work: "",
      },
    },
    preferences: {
      marketing: {
        email: false,
        phone: false,
        post: false,
        sms: false,
      },
      servicing: {
        online: false,
        post: false,
      }
    }
  }
}