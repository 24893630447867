import { Field, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { regexEmailValue } from '../../../../shared/helpers/regex';
import { FieldElementProps, getName, getPlaceholder } from '../FieldElement';
import styles from '../FieldElement.module.scss';
import { createFieldElementDataNameId } from '../helpers/form.helpers';

export const EmailFieldLayout = (props: FieldElementProps) => {
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailValidation, setEmailValidation] = useState<any>('');
  const formikProps = useFormikContext();
  useEffect(() => {
    setEmailValidation(
      props.options?.validation && emailValue === ''
        ? null
        : !regexEmailValue.test(emailValue)
          ? styles.error
          : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValue]);
  return (
    <>
      <Field
        className={`${props.options?.validation ? styles.email_field : styles.field} ${
          props.options?.validation && emailValidation
        }`}
        data-testid={getName(
          createFieldElementDataNameId(
            props.field,
            props.options?.pageName,
            props.options?.elementTypeName,
          ),
          props?.index,
          props.options?.useIndexInName,
        )}
        type={props.field.type}
        id={props.inputId}
        placeholder={getPlaceholder(
          props.field.type,
          props.field.placeholderText,
        )}
        name={props.inputId}
        onChange={(e: any) => {
          setEmailValue(e.target.value);
          formikProps.handleChange(e);
          props.handlers?.onChange(e);
        }}
        min={props.field.range?.min}
        max={props.field.range?.max}
        step={props.field.range?.step}
      />
    </>
  );
};
