import logo from '@assets/images/simplyhealth-logo-plus-strapline.svg';
import { SimplyPlanApp } from '@shared/parts/simply-plan-app/SimplyPlanApp';
import { getBrowserName } from './helper/GetBrowserName';
import styles from './InvalidBrowser.module.scss';
import { downloadBrowserModel } from './models/DownloadBrowserModel';
import { DownloadBrowserCard } from './parts/DownloadBrowserCard';
import { DownloadBrowserGrid } from './parts/DownloadBrowserGrid';

export const InvalidBrowser = (value: any) => {
  const browserName = getBrowserName();
  const browser = downloadBrowserModel.find(
    browser => browser.shortName === browserName,
  );

  return (
    <div className={styles.outer_container}>
      <img src={logo} alt="Logo" />
      <h1 className={styles.header}>
        Please upgrade your browser to use our portal
      </h1>
      <span className={`lead ${styles.text}`}>
        We have built our Simplyhealth portals on the latest technology. This
        makes our portal faster and easier to use.
      </span>
      <span className={`lead ${styles.text}`}>
        Unfortunately, your current browser version does not support those
        technologies.
      </span>
      <span className={`lead ${styles.text}`}>
        To upgrade your browser version please click on the appropriate link
        below:
        {browser !== undefined && (
          <DownloadBrowserCard model={browser} buttonText="Update" hasButton />
        )}
      </span>
      <span className={`lead ${styles.text}`}>
        Or use one of the following browsers:
      </span>
      <div>
        <DownloadBrowserGrid />
      </div>
      <div className={styles.app_wrapper}>
        <SimplyPlanApp
          title="Alternatively you can download our SimplyPlan app!"
          center={true}
        />
      </div>
    </div>
  );
};
