export const GP_BENEFITS: Array<string> = ["CSCPGP1", "CAPGP1", "1288"];
export const OPTICAL_BENEFITS: Array<string> = ["CSCPOPTICAL", "CPEYES", "922", "34", "89"];
export const DIAGNOSTIC_BENEFITS: Array<string> = ["CAPCONSULTATION", "CPCONSTESTSCANP", "CAPDIAGNOSTIC", "CSPCONSULTATION", "43", "74"];
export const SCANNING_BENEFITS: Array<string> = ["CAPRAYSCAN", "CPXRAYS", "CAPCONSULTATION", "CAPDIAGSCANS", "CPCONSTESTSCANP", "CAPDIAGNOSTIC", "CSPCONSULTATION", "141", "74", "970", "87", "43", "90"];
export const PHYSIO_BENEFITS: Array<string> = ["CAPPHYSIOTHERAP", "CSCPPHYSIOTHERA", "47", "852", "912", "902"]

export const COUNSELLING_CONS_CORP_VOL: Array<string> = ["1290", "CSCPEAPT", "CAPEAPT"];
export const COUNSELLING_CORP_F2F: Array<string> = ["1289"];
export const COUNSELLING_CORP_NON_F2F: Array<string> = ["1291"];
export const EAP_BENEFITS: Array<string> = [...COUNSELLING_CONS_CORP_VOL, ...COUNSELLING_CORP_F2F, ...COUNSELLING_CORP_NON_F2F]




