import React, { Fragment } from "react";
import { createImageUrl } from "@shared/helpers/helper-functions";
import styles from "./Footer.module.scss";
import { footerLinks } from "./models/footer-links.model";

const Links = () => {
  return (
    <Fragment>
      {footerLinks.map((item, index) => (
        <li key={index}>
          <a
            href={item.link}
            target="_blank"
            rel="noreferrer"
            data-testid="links"
          >
            {item.display}
          </a>
        </li>
      ))}
    </Fragment>
  );
};

const SocialMediaLinks = () => {
  const socLinkArray = [
    {
      href: "https://www.linkedin.com/company/simplyhealth",
      className: styles.linkedin,
      ariaLabel: "Linkedin",
      text: "LinkedIn",
    },
    {
      href: "https://www.facebook.com/simplyhealthuk/",
      className: styles.facebook,
      ariaLabel: "Facebook",
      text: "Facebook",
    },
    {
      href: "https://www.instagram.com/simplyhealthuk/",
      className: styles.instagram,
      ariaLabel: "Instagram",
      text: "Instagram",
    },
    {
      href: "https://twitter.com/SimplyhealthUK/",
      className: styles.twitter,
      ariaLabel: "Twitter",
      text: "Twitter",
    },
    {
      href: "https://www.youtube.com/channel/UCLOhC3tc2_Oy_6VQ6kvrhvw",
      className: styles.youtube,
      ariaLabel: "Youtube",
      text: "Youtube",
    },
  ];
  return (
    <Fragment>
      <ul>
        {socLinkArray.map((socLink, index) => (
          <li key={index}>
            <a
              href={socLink.href}
              className={socLink.className}
              aria-label={socLink.ariaLabel}
              target="_blank"
              rel="noreferrer"
              data-testid="socialMediaLinks"
            >
              <span className={styles.sr_only}>{socLink.text}</span>
            </a>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export const Footer = () => {
  return (
    <footer className={styles.outer_container}>
      <section className={styles.row_container}>
        <div className={styles.logo_section}>
          <a
            data-testid="logo"
            href="https://bcorporation.uk/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.bcorp_logo}
              src={createImageUrl("B-Corp-Logo-White-RGB.svg")}
              alt="BCorp log"
            />
          </a>
        </div>
        <div className={styles.social_section}>
          <SocialMediaLinks />
        </div>
      </section>
      <div>
        <nav className={styles.link_section}>
          <ul>
            <Links />
          </ul>
        </nav>
        <p className={styles.copyright}>
          &copy; Simplyhealth {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
