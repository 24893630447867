import AppleStore from '@assets/images/apple-store.svg';
import GooglePlay from '@assets/images/google-play.svg';
import { appleLink, googleLink } from '@shared/constants/site-constants';
import { setEventAnalytics } from '@shared/services/analytics/analytics-hooks';
import { Fragment } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import styles from './AppStoreLinks.module.scss';

interface AppStoreLinksProps {
  appleAnalytics: string;
  googleAnalytics: string;
}

export const AppStoreLinks = ({
  appleAnalytics,
  googleAnalytics,
}: AppStoreLinksProps) => {
  return (
    <Fragment>
      <div className={styles.container}>
        {isAndroid && (
          <a
            data-testid="Google_Download_Button"
            onClick={() => {
              setEventAnalytics([googleAnalytics]);
            }}
            href={googleLink}
            target="_blank"
            rel="noreferrer">
            <img src={GooglePlay} alt="Google play store link" />
          </a>
        )}
        {isIOS && (
          <a
            data-testid="Apple_Download_Button"
            onClick={() => {
              setEventAnalytics([appleAnalytics]);
            }}
            href={appleLink}
            target="_blank"
            rel="noreferrer">
            <img src={AppleStore} alt="Apple app store link" />
          </a>
        )}
      </div>
    </Fragment>
  );
};
