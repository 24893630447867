export const generateErrorMessage = (message: string, email?: string) => {
  switch (message) {
    case "An account with the given email already exists.": {
      return {
        prefix: "Just a minute!",
        firstLine: `The email address ${email} has already been taken.`,
        secondLine:
          "Try entering an alternate email address, or click the 'Sign in' link below to retrieve an existing account.",
      };
    }
    case "forgotPassword": {
      return {
        prefix: "You've missed a step.. ",
        firstLine: `Looks like you started to register your plan online but didn't complete the steps to verify your email.`,
        secondLine:
          "Once you've verified your email address, we'll know this email is yours and you'll be able to sign into your online account.",
      };
    }
    case "User unverified error": {
      return {
        prefix: "Ooops! ",
        firstLine: `Looks like you have started the registration process, however you still need to verify your email address.`,
      };
    }
    case "PreTokenGeneration failed with error Error generating token.": {
      return {
        prefix: "Error:",
        firstLine: `Our system is not available at the moment.`,
        secondLine: "This is just temporary, please try again later",
      };
    }
    default: {
      return { prefix: "Error:", firstLine: message };
    }
  }
};
