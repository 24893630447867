import React, { Fragment } from "react";
import { usePageAnalytics } from "@shared/services/analytics/analytics-hooks";
import { PageName } from "@authenticated/page-constants";
import { Button } from "@authenticated/parts/buttons/Button";
import { useTitle } from "@authenticated/onboarding/logic/useTitle";
import { Layout } from "../parts/Layout";

const PAGE_NAME: PageName = "OnboardingLoggedOut";

export const LoggedOut = () => {
  usePageAnalytics(PAGE_NAME);
  useTitle("Logged-out page");

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.location.replace(`${process.env.REACT_APP_URL_PREFIX}/member-portal`);
  };

  return (
    <Fragment>
      <Layout title="You have been logged out" imageSource="manhole.svg" imageAltText="Manhole and ladder">
        <div>
          <p>Unfortunately your login has expired.</p>
          <p>
            If you would like to continue, please return to the sign in page using the button below and enter your sign in
            details.
          </p>
          <form onSubmit={handleFormSubmit}>
            <Button type="submit" pageName={PAGE_NAME} name="login" styling="send">
              Sign in
            </Button>
          </form>
        </div>
      </Layout>
    </Fragment>
  );
};
