import * as Yup from "yup";
import { FieldMeta } from "@authenticated/parts/forms/helpers/form.helpers";
import { validationMessagesFor } from "@shared/helpers/validation-message.helpers";

const emailMessages = validationMessagesFor("Email address");
const passwordMessages = validationMessagesFor("Password");

export interface SignInFormConfig {
  email: FieldMeta;
  password: FieldMeta;
}

export const initialValues = {
  email: "",
  password: "",
};

export const signInForm: SignInFormConfig = {
  email: {
    name: "email",
    label: "Your email",
    type: "email",
    placeholderText: "Enter your email address",
    schema: Yup.string().required(emailMessages.required()).email(emailMessages.emailFormat()),
  },
  password: {
    name: "password",
    label: "Your password",
    type: "password",
    placeholderText: "Enter your password",
    schema: Yup.string().required(passwordMessages.required()),
  },
};

export const validationSchema = Yup.object({
  [signInForm.email.name]: signInForm.email.schema,
  [signInForm.password.name]: signInForm.password.schema,
});
