import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';

const EXCLUDED_DOMAINS: Array<string> = [
  '*onetrust.com',
  '*linkedin.com',
  '*pingdom.com',
  '*doubleclick.net',
  '*clarity.ms',
  '*demdex.net',
];

const INCLUDED_NAMES_FOR_FILTER: Array<string> = [
  'simplyhealth',
  'cognito',
  'api/member-portal',
];
const EXCLUDED_NAMES_FOR_FILTER: Array<string> = [
  'google-analytics',
  'pingdom',
  'mboxedge',
  'omtdrc',
  'doubleclick',
  'analytics.google',
];

export const getAppInsightsConfig = (
  reactPlugin: ReactPlugin,
): ApplicationInsights => {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString:
        process.env.REACT_APP_AZURE_APP_INSIGHTS_CONNECTION_STRING,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      correlationHeaderExcludedDomains: EXCLUDED_DOMAINS,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });
  appInsights.addTelemetryInitializer(filterRemoteDependencies);
  return appInsights;
};

const filterRemoteDependencies = (envelope: ITelemetryItem) => {
  if (envelope.baseType === 'RemoteDependencyData') {
    const target = envelope.baseData ? envelope.baseData.target : '';
    if (
      !target ||
      (INCLUDED_NAMES_FOR_FILTER.some(value => target.includes(value)) &&
        !EXCLUDED_NAMES_FOR_FILTER.some(value => target.includes(value)))
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
