export const days = () => {
  let days:any[] = []
  for(let i:number = 1; i < 32; i++) {
    days.push(i)
  }
  return (
    [
    <option key="0" value="" disabled>DD</option>,
    days.map((n, i) =>
      <option key={i} value={('' + n).padStart(2, '0')}>
        {('' + n).padStart(2, '0')}
      </option>)
    ]
  )
}

export const months = () => {
  const months = [1,2,3,4,5,6,7,8,9,10,11,12]
  return (
    [
    <option key="0" value="" disabled>MM</option>,
    months.map((n, i) =>
      <option key={i} value={('' + n).padStart(2, '0')}>
        {('' + n).padStart(2, '0')}
      </option>)
    ]
  )
}

export const years = () => {
  const lower = (new Date()).getFullYear() - 125
  const upper = (new Date()).getFullYear() - 16
  let years:any[] = []
  for(let i:number = upper; i >= lower; i--) {
    years.push(i)
  }
  return (
    [
      <option key="0" value="" disabled>YYYY</option>,
      years.map((n, i) => <option key={i} value={n}>{n}</option>)
    ]
  )
}
