import { DownloadBrowserModel } from "../models/DownloadBrowserModel"
import { createImageUrl } from "@shared/helpers/helper-functions"
import { Button } from "@authenticated/parts/buttons/Button"
import styles from "./DownloadBrowserCard.module.scss"

type ButtonText = "Download" | "Update"

interface DownloadBrowserCardInterface {
    model: DownloadBrowserModel,
    buttonText: ButtonText,
    hasButton: boolean
}

export const DownloadBrowserCard = (props: DownloadBrowserCardInterface) => {
    const handleClick = () => {
        window.location.href = props.model.link
    }

    return (
        <div className={styles.card_size_container} data-testid="cardContainer">
            <img src={createImageUrl(props.model.imageFileName)} alt={`${props.model.shortName} logo`} className={styles.card_image} />
            <div className={styles.card_text}>
                <strong>{props.model.title}</strong>
            </div>
            {props.hasButton &&
                <Button
                    name="DownloadBrowser"
                    pageName={"UnsupportedBrowser"}
                    styling="secondary"
                    type="button"
                    onClickHandler={() => handleClick()}>
                    {props.buttonText} {props.model.shortName}
                </Button>
            }
        </div>
    )
}