import { MemberType } from '@shared/state/models/app-state.model';
import { mapAccessControl } from '../mappers/access-control.mapper';
import { getMemberBenefits } from '../member.service';
import { AccessControl } from '../models/access-control.models';
import {
  MemberPolicyListModel,
  PolicySummaryModel,
} from '../models/policy-list.models';
import { GetUserResponse } from '../models/user.interfaces';
export interface MemberDetails {
  accessControl: AccessControl;
  memberPolicies: MemberPolicyListModel;
  memberType: MemberType;
  memberIds: Array<string>;
  memberBenefits: Array<string>;
  readOnly: boolean;
  smallestPolicyNumber: string;
}

export const loadMember = (
  userData?: GetUserResponse,
  memberPoliciesData?: MemberPolicyListModel,
): Promise<MemberDetails | undefined> => {
  const memberDetails = new Promise<MemberDetails | undefined>(
    async (resolve, reject) => {
      if (userData && memberPoliciesData) {
        const memberId =
          userData['simplyhealth:target_system'] === 'ski'
            ? userData['simplyhealth:ski_member_ids'][0]
            : userData['simplyhealth:target_system'] === 'pulse'
              ? userData['simplyhealth:pulse_member_ids'][0]
              : userData['simplyhealth:dhp_member_ids'];
        const memberType: MemberType =
          userData['simplyhealth:target_system'] === 'ski'
            ? 'Ski'
            : userData['simplyhealth:target_system'] === 'pulse'
              ? 'Pulse'
              : 'Dhp';
        if (memberId) {
          const memberIds = [
            ...userData['simplyhealth:ski_member_ids'],
            ...userData['simplyhealth:pulse_member_ids'],
          ];

          Promise.all(memberIds.map(memberId => getMemberBenefits(memberId)))
            .then(memberBenefitsResponses => {
              const memberBenefits: Array<string> = [];

              memberBenefitsResponses.forEach(response => {
                memberBenefits.push(
                  ...response.data.map(response => {
                    return response.id;
                  }),
                );
              });

              const smallestPolicyNumber =
                memberPoliciesData.policies.length > 0
                  ? memberPoliciesData.policies.reduce((res, obj) =>
                      obj.id < res.id ? obj : res,
                    )
                  : { id: '0' };

              const accessControl = mapAccessControl(
                userData,
                memberBenefits,
                memberPoliciesData,
              );

              if (
                userData['simplyhealth:dhp_member_ids'] &&
                userData['simplyhealth:dhp_member_ids'].length > 0
              ) {
                memberIds.push(...userData['simplyhealth:dhp_member_ids']);
              }

              resolve({
                accessControl,
                memberPolicies: memberPoliciesData,
                memberType,
                readOnly: userData['simplyhealth:is_read_only'],
                smallestPolicyNumber: smallestPolicyNumber.id,
                memberIds,
                memberBenefits,
              });
            })
            .catch(error => {
              console.error(error);
              reject('Error');
            });
        } else {
          resolve(undefined);
        }
      } else {
        resolve(undefined);
      }
    },
  );
  return memberDetails;
};

export const getMemberIdForPolicy = (
  memberPolicies: MemberPolicyListModel,
  policyId: string,
): string => {
  if (policyId && memberPolicies) {
    const policy = getSelectedPolicy(memberPolicies, policyId);
    return policy ? policy.policyholderId : '';
  } else {
    return '';
  }
};

export const getSelectedPolicy = (
  memberPolicies: MemberPolicyListModel,
  policyId: string,
): PolicySummaryModel => {
  return memberPolicies.policies?.filter(policy => {
    return policy.id === policyId;
  })[0] as unknown as PolicySummaryModel;
};
