import { setEventAnalytics } from "@shared/services/analytics/analytics-hooks";
import { PageName } from "@authenticated/page-constants";
import { Button } from "../buttons/Button";
import styles from "./ContactUs.module.scss";

export interface ContactUsProps {
  pageName: PageName;
}
export const ContactUs = (props: ContactUsProps) => {
  return (
    <div className={styles.outer_container}>
      <h3>Contact us</h3>
      <p>Find out about our contact options</p>
      <div className={styles.button_container}>
        <a
          onClick={() => {
            setEventAnalytics(["event204"]);
          }}
          href="https://www.simplyhealth.co.uk/existing-customers/get-in-touch"
          target="_blank"
          rel="noreferrer"
        >
          <Button name="ContactUs" pageName={props.pageName} styling="primary" type="button">
            Contact us
          </Button>
        </a>
      </div>
    </div>
  );
};
