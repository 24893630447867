import React, { Dispatch, ReactNode, createContext, useContext, useReducer } from "react";
import {StateActions } from "./actions/actions";
import { appStateReducer } from "./reducers/reducers";

import { AppStateModel } from "./models/app-state.model";


// Create two contexts, state and dispatch
export const stateContext = createContext<AppStateModel | undefined>(undefined);
export const stateDispatchContext = createContext<Dispatch<StateActions> | undefined>(undefined);

// Create component props (consumed by parent component)
export interface AppStateContextProviderProps {
  children: ReactNode;
  initialState: AppStateModel;
}

export const AppStateContextProvider = (props: AppStateContextProviderProps) => {
  const [state, dispatch] = useReducer(appStateReducer, props.initialState);
  return (
    <stateContext.Provider value={state}>
      <stateDispatchContext.Provider value={dispatch}>{props.children}</stateDispatchContext.Provider>
    </stateContext.Provider>
  );
};

// Create the function to use state and use dispatch (child components use these)
export const useStateContext = () => useContext(stateContext) as AppStateModel;
export const useStateDispatchContext = () =>
  useContext(stateDispatchContext) as Dispatch<StateActions>;
