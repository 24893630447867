import { createImageUrl } from '@shared/helpers/helper-functions';

import styles from './Layout.module.scss';

export const siteTitle = 'Simplyhealth Onboarding application';

interface ILayout {
  title: string;
  titleSecondLine?: string;
  imageSource?: string;
  imageAltText?: string;
  children: React.ReactNode;
}

export const Layout = ({
  title,
  imageSource,
  imageAltText,
  children,
  titleSecondLine: titleSeccondLine,
}: ILayout) => {
  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_container}>
        {imageSource && imageAltText && (
          <div className={styles.image_container}>
            <img
              src={createImageUrl(imageSource)}
              alt={imageAltText}
              data-testid="layout-image"
            />
          </div>
        )}
        <header>
          <h1 className={styles.header} data-testid="layout-header">
            {title}
            <br />
            {titleSeccondLine}
          </h1>
        </header>
        <main>{children}</main>
      </div>
    </div>
  );
};
