import { Auth } from "@aws-amplify/auth";
import axios, { AxiosInstance, RawAxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";

const GET_RETRY_COUNT: number = 2;

export function getAxiosInstance(baseUrl: string | undefined): AxiosInstance {
  const masterConfig: RawAxiosRequestConfig = {
    baseURL: baseUrl,
    headers:
      { 'x-api-client': 'CustomerPortal' },
  };
  return axios.create(masterConfig);
}

export async function secureGet<TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  queryParameters?: any,
  additionalConfig?: any
): Promise<TResponse> {
  const additionalHeader = {
    Authorization: "Bearer " + (await getAuthorization()),
  };
  return get(
    baseUrl,
    featureUrl,
    queryParameters,
    additionalHeader,
    additionalConfig
  );
}

export async function get<TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  queryParameters?: any,
  additionalHeader?: any,
  additionalConfig?: any
): Promise<TResponse> {
  try {
    const axiosInstance = getAxiosInstance(baseUrl);
    axiosRetry(axiosInstance, { retries: GET_RETRY_COUNT });
    const localConfig: RawAxiosRequestConfig = {
      ...additionalConfig,
      headers: additionalHeader,
      params: { ...queryParameters },
    };
    const response = await axiosInstance.get<TResponse>(
      featureUrl,
      localConfig
    );
    return response.data;
  } catch (error) {
    throw new Error("Unable to retrieve information from the server.");
  }
}

export async function securePost<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any,
  localConfig?: RawAxiosRequestConfig,
  returnRootResponse?: boolean
): Promise<TResponse> {
  additionalHeader = {
    ...additionalHeader,
    Authorization: "Bearer " + (await getAuthorization()),
  };
  return post(
    baseUrl,
    featureUrl,
    request,
    queryParameters,
    additionalHeader,
    localConfig,
    returnRootResponse
  );
}

export async function post<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any,
  localConfig?: RawAxiosRequestConfig,
  returnRootResponse?: boolean
): Promise<TResponse> {
  try {
    const axiosInstance = getAxiosInstance(baseUrl);
    const axiosLocalConfig: RawAxiosRequestConfig = {
      ...localConfig,
      headers: { ...additionalHeader },
      params: { ...queryParameters },
    };

    const response = await axiosInstance
      .post(featureUrl, request, axiosLocalConfig)
      .then((response) => (returnRootResponse ? response : response.data));

    return response as TResponse;
  } catch (error) {
    throw new Error("Unable to send information to the server.");
  }
}

export async function securePostWithId<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any,
  localConfig?: RawAxiosRequestConfig,
  id?: string
): Promise<TResponse> {
  additionalHeader = {
    ...additionalHeader,
    Authorization: "Bearer " + (await getAuthorization()),
  };
  return postWithId(
    baseUrl,
    featureUrl,
    request,
    queryParameters,
    additionalHeader,
    localConfig,
    id
  );
}

export async function postWithId<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any,
  localConfig?: RawAxiosRequestConfig,
  id?: string
): Promise<TResponse> {
  try {
    const axiosInstance = getAxiosInstance(baseUrl);
    const axiosLocalConfig: RawAxiosRequestConfig = {
      ...localConfig,
      headers: { ...additionalHeader },
      params: { ...queryParameters },
    };

    const response = await axiosInstance
      .post(featureUrl, request, axiosLocalConfig)
      .then((response) => response);

    let responseWithId: any =
      response.status < 300
        ? ({
          data: response.data.data,
          status: response.status,
        } as unknown as TResponse)
        : ({
          errorData: response.data,
          status: response.status,
        } as unknown as TResponse);
    responseWithId.id = id;
    return responseWithId;
  } catch (error) {
    throw new Error("Unable to send information to the server.");
  }
}

export async function secureDelete<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any
): Promise<TResponse> {
  additionalHeader = {
    ...additionalHeader,
    Authorization: "Bearer " + (await getAuthorization()),
  };
  return doDelete(
    baseUrl,
    featureUrl,
    request,
    queryParameters,
    additionalHeader
  );
}

export async function doDelete<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any
): Promise<TResponse> {
  try {
    const axiosInstance = getAxiosInstance(baseUrl);
    const localConfig: RawAxiosRequestConfig = {
      headers: { ...additionalHeader },
      params: { ...queryParameters },
      data: { ...request },
    };

    const response = await axiosInstance
      .delete(featureUrl, localConfig)
      .then((response) => response.data);

    return response as TResponse;
  } catch (error) {
    throw new Error("Unable to send information to the server.");
  }
}

export async function securePatch<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any
): Promise<TResponse> {
  additionalHeader = {
    ...additionalHeader,
    Authorization: "Bearer " + (await getAuthorization()),
  };
  return patch(baseUrl, featureUrl, request, queryParameters, additionalHeader);
}

export async function patch<TRequest, TResponse>(
  baseUrl: string | undefined,
  featureUrl: string,
  request: TRequest,
  queryParameters?: any,
  additionalHeader?: any
): Promise<TResponse> {
  try {
    const axiosInstance = getAxiosInstance(baseUrl);
    const localConfig: RawAxiosRequestConfig = {
      headers: { ...additionalHeader },
      params: { ...queryParameters },
    };

    const response = await axiosInstance
      .patch(featureUrl, request, localConfig)
      .then((response) => response.data);

    return response as TResponse;
  } catch (error) {
    throw new Error("Unable to send information to the server.");
  }
}

export const getAuthorization = async (): Promise<string> => {
  let res = await Auth.currentSession();
  return res.getAccessToken().getJwtToken();
};
