import { createImageUrl } from "@shared/helpers/helper-functions";

import { Footer } from "@authenticated/parts/footer/Footer";

import logo from "@assets/images/simplyhealth-logo-plus-strapline.svg";
import arrow from "@assets/images/arrow-back.svg";
import styles from "./Layout.module.scss";

export const siteTitle = "Simplyhealth Onboarding application";

interface ILayout {
  title: string;
  imageSource: string;
  imageAltText: string;
  children: React.ReactNode;
  showBack?: () => string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const Layout = ({ title, imageSource, imageAltText, children, showBack, onClick }: ILayout) => {
  return (
    <div className={styles.outer_container}>
      <div>
        <div className={styles.logo_container}>
          <a href="https://www.simplyhealth.co.uk/" role="button">
            <img src={logo} alt="Company Logo" />
          </a>
        </div>
        {showBack && (
          <div className={styles.back_button_wrapper}>
            <button className={styles.button} type="button" onClick={onClick} data-testid="backButton">
              <img src={arrow} alt="Arrow Back" />
              Back
            </button>
          </div>
        )}
        <div className={styles.inner_container}>
          <div className={styles.form_wrapper}>
            <div className={styles.form_container}>
              <div className={styles.image_container}>
                <img src={createImageUrl(imageSource)} alt={imageAltText} />
              </div>
              <header>
                <h1 className={styles.header} data-testid="layout-header">{title}</h1>
              </header>
              <main>{children}</main>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
