import { Fragment, useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Authenticator } from "@aws-amplify/ui-react";
import { coginitoConfiguration } from "@shared/cognito/configure";
import "@aws-amplify/ui-react/styles.css";
import "./App.scss";
import { getStatus } from "@authenticated/services/status.service";
import { SiteUnavailable } from "@unauthenticated/site-unavailable/SiteUnavailable";
import { PageStatus } from "@authenticated/page-constants";

import { browserVersion } from "react-device-detect";
import { InvalidBrowser } from "@unauthenticated/invalid-browser/InvalidBrowser";
import { getBrowserName } from "@unauthenticated/invalid-browser/helper/GetBrowserName";
import { ApplicationView } from "./ApplicationView";
import { setSessionAnalytics } from "@shared/services/analytics/analytics-hooks";

import { AppInsightsContext, ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { getAppInsightsConfig } from "./app-insights.config";

var reactPlugin = new ReactPlugin();
var appInsights = getAppInsightsConfig(reactPlugin);
appInsights.loadAppInsights();

Auth.configure(coginitoConfiguration);

type minimumBrowserVersionType = {
  Name: string;
  Value: string;
};

function App() {
  const [siteAvailable, setSiteAvailable] = useState<boolean>(true);
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loading");

  const [minBrowserVersion, setMinBrowserVersion] = useState<number>(0);

  useEffect(() => {
    const minimumBrowserVersion = [
      {
        Name: "Chrome",
        Value: "50",
      },
      {
        Name: "Edge",
        Value: "79",
      },
      {
        Name: "Firefox",
        Value: "44",
      },
      {
        Name: "Safari",
        Value: "11",
      },
    ] as minimumBrowserVersionType[];
    const browserName = getBrowserName();
    let browser = minimumBrowserVersion.find((browser) => {
      return browser.Name === browserName;
    });

    if (
      browser &&
      browserVersion.localeCompare(browser.Value, undefined, { numeric: true, sensitivity: "base" }) === -1
    ) {
      setMinBrowserVersion(parseFloat(browser.Value));
    }
  }, []);

  useEffect(() => {
    getStatus()
      .then((response) => {
        setSiteAvailable(response.attributes.available);
        setPageStatus("Loaded");
      })
      .catch((error) => {
        console.error(error);
        setSiteAvailable(false);
        setPageStatus("Loaded");
      });
  }, []);

  useEffect(() => {
    setSessionAnalytics();
  }, []);

  return (
    <Fragment>
      <HelmetProvider>
        <script type="text/javascript">
          {`var scripts = document.scripts;
        for (var i = 0; i < scripts.length; i++) {
          if (scripts[i].src.includes("/static/js/main.")) {
            scripts[i].setAttribute('data-ot-ignore', '')
          }
        }`}
        </script>
      </HelmetProvider>
      {pageStatus === "Loading" ? (
        <div></div>
      ) : siteAvailable ? (
        minBrowserVersion === 0 ? (
          <div>
            <AppInsightsContext.Provider value={reactPlugin}>
              <Authenticator.Provider>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={'en-gb'}>
                  <ApplicationView />
                </LocalizationProvider>
              </Authenticator.Provider>
            </AppInsightsContext.Provider>
          </div>
        ) : (
          <InvalidBrowser minBrowserVersion={minBrowserVersion} />
        )
      ) : (
        <SiteUnavailable />
      )}
    </Fragment>
  );
}

export default withAITracking(reactPlugin, App);
