import calendar from '@assets/images/calendar.svg';
import errorImage from '@assets/images/error.svg';
import {
  formatDateForApi,
  formatDateForMessage,
} from '@shared/helpers/date-helpers';
import { useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import { FieldElementProps } from '../FieldElement';
import styles from '../FieldElement.module.scss';

export const FlatpickrFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  const inputEl = useRef<any>(null);
  const [date, setDate] = useState<any>();

  // @ts-ignore
  const minDate = formatDateForApi(new Date(props.options?.minClaimDate));

  // @ts-ignore
  const maxDate = formatDateForApi(new Date(props.options?.maxClaimDate));

  var input = document.querySelectorAll(`.date-${props.options?.id}`)[0];

  var dateInputMask = function dateInputMask(elm) {
    if (!elm) return;
    elm.addEventListener('keypress', function (element) {
      if (!/[0-9\s.$]/.test(String.fromCharCode(element.keyCode))) {
        element.preventDefault();
      }
      if (element.keyCode < 47 || element.keyCode > 57) {
        element.preventDefault();
      }
      var len = elm.value.length;
      if (len !== 1 || len !== 3) {
        if (elm.keyCode === 47) {
          elm.preventDefault();
        }
      }
      if (len === 1) {
        if (
          elm.value === '4' ||
          elm.value === '5' ||
          elm.value === '6' ||
          elm.value === '7' ||
          elm.value === '8' ||
          elm.value === '9'
        ) {
          elm.value = 0 + elm.value + '/';
        }
      }
      if (len === 4) {
        if (
          elm.value[3] === '2' ||
          elm.value[3] === '3' ||
          elm.value[3] === '4' ||
          elm.value[3] === '5' ||
          elm.value[3] === '6' ||
          elm.value[3] === '7' ||
          elm.value[3] === '8' ||
          elm.value[3] === '9'
        ) {
          elm.value = elm.value.split('/').join('/0') + '/';
        }
      }
      if (len === 2) {
        elm.value += '/';
      }
      if (len === 5) {
        elm.value += '/';
      }
    });
  };

  dateInputMask(input);

  return (
    <div>
      <div className={styles.calendar_wrapper}>
        <Flatpickr
          ref={inputEl}
          type="number"
          id={props.inputId}
          name={props.inputId}
          value={props.options?.treatmentDate}
          options={{
            dateFormat: 'd/m/Y',
            maxDate: props.options?.maxClaimDate,
            minDate: props.options?.minClaimDate?.toLocaleString('en-GB', {
              timeZone: 'GMT',
            }),
            disableMobile: true,
            allowInput: true,
          }}
          className={`flatpickr-input date-${props.options ? props.options.id : ''}`}
          maxLength={10}
          placeholder="dd/mm/yyyy"
          onClose={e => {
            if (e && e.length > 0) {
              let element = {
                target: {
                  value: formatDateForApi(new Date(e[0])),
                },
              };
              formikProps.handleChange(element);
              props.handlers?.onChange(element);
              setDate(element.target.value);
            }
          }}
          onChange={e => {
            if (e && e.length > 0) {
              let element = {
                target: {
                  value: formatDateForApi(new Date(e[0])),
                },
              };
              formikProps.handleChange(element);
              props.handlers?.onChange(element);
              setDate(element.target.value);
            }
          }}
        />
        <img
          src={calendar}
          alt="Calendar"
          className={styles.calendar}
          onClick={() => {
            inputEl.current.flatpickr.open();
          }}
        />
      </div>
      {date === null ? (
        <div className={styles.error_message}>
          <img src={errorImage} className={styles.error_image} alt="Error" />
          Date cannot be before{' '}
          {props.options?.id === 4
            ? 'Accident Date'
            : formatDateForMessage(new Date(minDate))}{' '}
          and after {formatDateForMessage(new Date(maxDate))}
        </div>
      ) : null}
    </div>
  );
};
