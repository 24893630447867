import { Fragment, useState } from "react";
import { Field, useFormikContext } from "formik";

import { FieldElementProps, getName, getPlaceholder } from "../FieldElement";
import { createFieldElementDataNameId } from "../helpers/form.helpers";
import styles from "../FieldElement.module.scss";

export const TextAreaFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  const [count, setCount] = useState(0);
  return (
    <Fragment>
      <Field
        as="textarea"
        className={styles.textarea}
        data-testid={getName(
          createFieldElementDataNameId(props.field, props.options?.pageName, props.options?.elementTypeName),
          props?.index,
          props.options?.useIndexInName
        )}
        type={props.field.type}
        id={props.inputId}
        placeholder={getPlaceholder(props.field.type, props.field.placeholderText)}
        name={props.inputId}
        maxLength={props.field.range?.max ? props.field.range.max : ""}
        onChange={(e: any) => {
          setCount(e.target.value.length);
          formikProps.handleChange(e);
          props.handlers?.onChange(e);
        }}
      />
      {props.field.range && (
        <div className={styles.textareaCount}>{+props.field.range.max - count} characters left</div>
      )}
    </Fragment>
  );
};
