import { formatDateForMessage } from "./date-helpers";

export const validationMessagesFor = (fieldName: string) => {
  const required = () => `${fieldName} is required.`;
  const checked = () => `${fieldName} should be ticked`;
  const email = () => `${fieldName} is not valid`;
  const emailFormat = () => "Email format is not valid";
  const match = (otherFieldName: string) =>
    `${fieldName} and ${otherFieldName} should match.`;
  const format = (formatDescription: string) =>
    `${fieldName} should be ${formatDescription}.`;
  const phone = () => "Please enter a valid phone number";
  const maxLength = (max: number) =>
    `${fieldName} should be no longer than ${max} characters.`;
  const minLength = (min: number) =>
    `${fieldName} should be no shorter than ${min} characters.`;
  const maxDate = (maxDate: Date) =>
    `${fieldName} cannot be after ${formatDateForMessage(maxDate)}`;
  const minDate = (minDate: Date) =>
    `${fieldName} cannot be before ${formatDateForMessage(minDate)}`;
  const minAmount = (minAmount: number) =>
    `${fieldName} must be at least ${minAmount}`;
  const maxAmount = (maxAmount: number) =>
    `${fieldName} must be no more than ${Number(maxAmount).toLocaleString()}`;
  const invalid = () => `${fieldName} is invalid`;
  const invalidCharacters = (validCharacters: string) =>
    `${fieldName} can only contain ${validCharacters}`;
  const invalidCharactersForTextInput = () =>
    "Sorry, you’ve entered an invalid character. Only use letters (a-z), numbers (1-9) and the following special characters & £ . , : ; ' - ^";
  return {
    required,
    checked,
    email,
    emailFormat,
    match,
    format,
    phone,
    maxLength,
    minLength,
    maxDate,
    minDate,
    minAmount,
    maxAmount,
    invalid,
    invalidCharacters,
    invalidCharactersForTextInput,
  };
};

export const FIRST_NAME_MAX_LENGTH = 100;
export const SURNAME_MAX_LENGTH = 100;
export const POLICYNUMBER_MAX_LENGTH = 10;

export const CHARACTER_REGEX = /^[ a-zA-Z0-9&£.,:;'\-^]+$/;
