export const deriveCurrencyAmountForDisplay = (inputNumber: number): string => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(Math.abs(inputNumber));
};

export const formatPhoneNumber = (inputNumber: string): string => {
  if (inputNumber.length !== 11) {
    return inputNumber;
  }
  return (
    inputNumber.slice(0, 4) +
    ' ' +
    inputNumber.slice(4, 7) +
    ' ' +
    inputNumber.slice(7, 11)
  );
};

export const generateRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const formatNumber = (
  inputNumber: string | number
): string => {
  let value = inputNumber + '';
  if (value.length > 3) {
    value = value.slice(0, -3) + 'k';
  };
  return value;
}

export const roundNumber = (inputNumber: number, decimalPlaces: number): string => {
  return inputNumber.toFixed(decimalPlaces);
}