import { FieldMeta } from "@authenticated/parts/forms/helpers/form.helpers";
import * as Yup from "yup";

import { validationMessagesFor } from "@shared/helpers/validation-message.helpers";

const servicingMessages = validationMessagesFor("Servicing");
const marketingTextMessages = validationMessagesFor("Text");
const marketingEmailMessages = validationMessagesFor("Email");
const marketingPhoneMessages = validationMessagesFor("Phone");
const marketingPostMessages = validationMessagesFor("Post");

export interface PreferencesFormConfig {
  servicing: FieldMeta;
  marketing: {
    post: FieldMeta;
    email: FieldMeta;
    phone: FieldMeta;
    sms: FieldMeta;
  };
}

export const preferences: PreferencesFormConfig = {
  servicing: {
    name: "servicing",
    label: "",
    type: "radio",
    items: [
      { label: "Post", value: "post" },
      { label: "Online", value: "online" },
    ],
    schema: Yup.string().required(servicingMessages.required()),
  },
  marketing: {
    sms: {
      name: "sms",
      label: "SMS (text message)",
      type: "radio",
      items: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
      schema: Yup.string().required(marketingTextMessages.required()),
    },
    email: {
      name: "email",
      label: "Email",
      type: "radio",
      items: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
      schema: Yup.string().required(marketingEmailMessages.required()),
    },
    phone: {
      name: "phone",
      label: "Phone",
      type: "radio",
      items: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
      schema: Yup.string().required(marketingPhoneMessages.required()),
    },
    post: {
      name: "post",
      label: "Post",
      type: "radio",
      items: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
      schema: Yup.string().required(marketingPostMessages.required()),
    },
  },
};
