export const numberOfPreviousClaimingYears: number = 7;
export const urlPrefix: string = `${process.env.REACT_APP_URL_PREFIX}/member-portal`;

export const minLegacyPasswordLength: number = 8;
export const minPasswordLength: number = 10;
export const maxPasswordLength: number = 99;

export const queryStaleTime = 1800000; // 30 minutes
export const queryGCTime = 1800000; // 30 minutes

export const appleLink =
  'https://apps.apple.com/us/app/simplyplan-by-simplyhealth/id1553287152';
export const googleLink =
  'https://play.google.com/store/apps/details?id=co.uk.simplyhealth.simplyplan&hl=en_GB';
