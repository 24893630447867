import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import errorImage from '@assets/images/error.svg';
import { PageName, PageStatus } from '@authenticated/page-constants';
import { Button } from '@authenticated/parts/buttons/Button';
import { SubmitButton } from '@authenticated/parts/buttons/SubmitButton';
import { FieldElement } from '@authenticated/parts/forms/FieldElement';
import { routePaths as authenticatedPaths } from '@authenticated/routing/models/route-paths';
import { buildPath } from '@shared/helpers/build-dynamic-path';
import { errorToast, successToast } from '@shared/helpers/toast';
import { PasswordValidationList } from '@shared/parts/password-validation/PasswordValidationList';
import { usePageAnalytics } from '@shared/services/analytics/analytics-hooks';
import { Layout } from '@unauthenticated/parts/Layout';
import {
  initialValues,
  registerForm,
  validationSchema,
} from './form-meta/register.form-config';
import { generateErrorMessage } from './logic/generateErrorMessage.helper';
import styles from './Register.module.scss';

export const Register = () => {
  const [pageStatus, setPageStatus] = useState<PageStatus>('Loaded');
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState<string>('');
  const [pageName, setPageName] = useState<PageName>('Register');
  const [errorMessage, setErrorMessage] = useState<any>();

  usePageAnalytics(pageName);

  useEffect(() => {
    if (stage === 2) {
      setPageName('RegisterConfirmation');
    }
  }, [stage]);

  const handleSubmit = (values: any) => {
    setEmail(values.email);
    const authData = {
      username: values.email.toLowerCase(),
      password: values.password,
      attributes: {
        email: values.email.toLowerCase(),
      },
    };

    Auth.signUp(authData)
      .then(response => {
        if (response.userConfirmed) {
          Auth.signIn(values.email.toLowerCase(), values.password)
            .then(_signInResponse => {
              setRedirectTo(buildPath(authenticatedPaths.home, []));
              setPageStatus('Next Page');
            })
            .catch(error => {
              console.error(error);
              setErrorMessage(generateErrorMessage(error.message, emailValue));
              errorToast(`Error: ${error.message}`, pageName);
            });
        } else {
          setStage(2);
        }
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(generateErrorMessage(error.message, emailValue));
        errorToast(`Error: ${error.message}`, pageName);
      });
  };

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then(() => {
        successToast('Email resent successfully', pageName);
      })
      .catch(error => {
        setErrorMessage(generateErrorMessage(error.message, emailValue));
        errorToast(error.message, pageName);
      });
  };

  return (
    <Fragment>
      {pageStatus === 'Next Page' ? (
        <Navigate to={redirectTo} />
      ) : (
        <>
          {stage === 1 ? (
            <Layout
              title="Register"
              imageSource="register-illustration.svg"
              imageAltText="Register Illustration">
              {errorMessage && (
                <div className={styles.error_message_wrapper}>
                  <img
                    src={errorImage}
                    className={styles.error_image}
                    alt="Error"
                  />
                  <div>
                    <div className={styles.first_line_wrapper}>
                      <p
                        className={styles.error_message}
                        data-testid="error-line-1">
                        <span className={styles.error_message_prefix}>
                          {errorMessage.prefix}
                        </span>{' '}
                        {errorMessage.firstLine}
                      </p>
                    </div>
                    {errorMessage.secondLine && (
                      <p
                        className={`${styles.error_message} ${styles.second_line_wrapper}`}
                        data-testid="error-line-2">
                        {errorMessage.secondLine}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.info_wrapper}>
                <p className={styles.register_info}>
                  <span className={styles.bold}>
                    Already purchased a Simplyhealth Plan?
                  </span>{' '}
                  In order to activate your account, please complete the
                  following steps.
                </p>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
                <Form>
                  <FieldElement
                    field={registerForm.email}
                    options={{
                      registerLabel: true,
                      validation: true,
                      pageName: pageName,
                    }}
                    handlers={{
                      onChange: e => {
                        setEmailValue(e.target.value);
                      },
                    }}></FieldElement>
                  <FieldElement
                    field={registerForm.confirmEmail}
                    options={{
                      registerLabel: true,
                      validation: true,
                      pageName: pageName,
                    }}></FieldElement>
                  <FieldElement
                    field={registerForm.password}
                    handlers={{
                      onChange: e => {
                        setPasswordValue(e.target.value);
                      },
                    }}
                    options={{
                      hideErrorMessage: true,
                      registerLabel: true,
                      poundValidation: true,
                      pageName: pageName,
                    }}></FieldElement>
                  <span className={styles.password_header}>Password must:</span>
                  <ul className={styles.password_requirements}>
                    <PasswordValidationList passwordValue={passwordValue} />
                  </ul>
                  <FieldElement
                    field={registerForm.confirmPassword}
                    options={{
                      registerLabel: true,
                      pageName: pageName,
                    }}></FieldElement>
                  <SubmitButton
                    buttonClickedText="Registering..."
                    buttonName="CreateAccount"
                    buttonText="Register"
                    pageName={pageName}
                    pageStatus={pageStatus}
                    styling="send"></SubmitButton>
                </Form>
              </Formik>
              <p className={styles.login_redirect}>
                Already registered your account?{' '}
                <a className={styles.link} href={authenticatedPaths.home.path}>
                  Sign in
                </a>
              </p>
            </Layout>
          ) : (
            <Layout
              title="Ok great!"
              titleSecondLine="Now check your email..."
              imageSource="Emails_AppIllustrations.png"
              imageAltText="Successful">
              <p className={styles.text}>
                We've sent you an email to{' '}
                <span className={styles.email}>{email}</span>
              </p>
              <p className={styles.info}>
                Within it, you'll find instructions to verify your email
                account. Note: The link will expire within 24 hours.
              </p>
              <div>
                <p className={`${styles.text} ${styles.no_spacing}`}>
                  Not received email?
                </p>
                <p className={styles.resend_button_wrapper}>
                  Remember to check your spam and junk folder.{' '}
                  <Button
                    pageName={pageName}
                    onClickHandler={resendCode}
                    styling="anchor"
                    name="ResendEmail"
                    type="button">
                    Resend email
                  </Button>
                </p>
              </div>
            </Layout>
          )}
        </>
      )}
    </Fragment>
  );
};
