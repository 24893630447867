import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";

import * as dateOfBirthRange from "@authenticated/onboarding/logic/date-of-birth-range";
import { FieldElementProps, getName } from "../FieldElement";
import styles from "../FieldElement.module.scss";
import { createFieldElementDataNameId } from "../helpers/form.helpers";

export const DateOfBirthFieldLayout = (props: FieldElementProps) => {
  const formikProps = useFormikContext();
  const [dayValue, setDayValue] = useState<string>("");
  const [monthValue, setMonthValue] = useState<string>("");
  const [yearValue, setYearValue] = useState<string>("");
  let dayValidation = dayValue === "" ? null : dayValue !== "" ? styles.success : styles.error;
  let monthValidation = monthValue === "" ? null : monthValue !== "" ? styles.success : styles.error;
  let yearValidation = yearValue === "" ? null : yearValue !== "" ? styles.success : styles.error;

  let { dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear }: any = formikProps.values;

  useEffect(() => {
    setDayValue(dateOfBirthDay);
    setMonthValue(dateOfBirthMonth);
    setYearValue(dateOfBirthYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.date_of_birth_wrapper}>
      <Field
        id="dateOfBirthDay"
        name="dateOfBirthDay"
        component="select"
        label="Date of birth"
        data-testid={getName(
          createFieldElementDataNameId(props.field, props.options?.pageName, props.options?.elementTypeName, "day"),
          props?.index,
          props.options?.useIndexInName
        )}
        onChange={(e: any) => {
          setDayValue(e.target.value);
          props.handlers?.onChange(e);
          formikProps.handleChange(e);
        }}
        className={`${styles.date_field} ${dayValidation}`}
      >
        {dateOfBirthRange.days()}
      </Field>
      <Field
        id="dateOfBirthMonth"
        name="dateOfBirthMonth"
        component="select"
        data-testid={getName(
          createFieldElementDataNameId(props.field, props.options?.pageName, props.options?.elementTypeName, "month"),
          props?.index,
          props.options?.useIndexInName
        )}
        onChange={(e: any) => {
          setMonthValue(e.target.value);
          props.handlers?.onChange(e);
          formikProps.handleChange(e);
        }}
        className={`${styles.date_field} ${monthValidation}`}
      >
        {dateOfBirthRange.months()}
      </Field>
      <Field
        id="dateOfBirthYear"
        name="dateOfBirthYear"
        component="select"
        label="Date of birth"
        data-testid={getName(
          createFieldElementDataNameId(props.field, props.options?.pageName, props.options?.elementTypeName, "year"),
          props?.index,
          props.options?.useIndexInName
        )}
        onChange={(e: any) => {
          setYearValue(e.target.value);
          props.handlers?.onChange(e);
          formikProps.handleChange(e);
        }}
        className={`${styles.date_field} ${yearValidation}`}
      >
        {dateOfBirthRange.years()}
      </Field>
    </div>
  );
};
