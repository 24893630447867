import { Dispatch, SetStateAction, useState } from "react";
import { FieldElementProps } from "../FieldElement";
import { useFormikContext } from "formik";
import styles from "../FieldElement.module.scss";
import errorImage from "@assets/images/error.svg";
import { formatDateForApi, formatDateForMessage } from "@shared/helpers/date-helpers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";

export const MuiPickerFieldLayout = (props: FieldElementProps, setDifferentValue: Dispatch<SetStateAction<boolean>> | undefined) => {
  const formikProps = useFormikContext();
  const [date, setDate] = useState<any>();
  const { options } = props;
  // @ts-ignore
  const minDate = formatDateForApi(new Date(options?.minClaimDate));

  // @ts-ignore
  const maxDate = formatDateForApi(new Date(options?.maxClaimDate));

  const input = document.querySelectorAll(`.date-${options?.id}`)[0];
  const dateInputMask = function dateInputMask(elm) {
    if (!elm) return;
    elm.addEventListener("keypress", function (element) {
      if (!/[0-9\s.$]/.test(String.fromCharCode(element.keyCode))) {
        element.preventDefault();
      }
      if (element.keyCode < 47 || element.keyCode > 57) {
        element.preventDefault();
      }
      var len = elm.value.length;
      if (len !== 1 || len !== 3) {
        if (elm.keyCode === 47) {
          elm.preventDefault();
        }
      }
      if (len === 1) {
        if (
          elm.value === "4" ||
          elm.value === "5" ||
          elm.value === "6" ||
          elm.value === "7" ||
          elm.value === "8" ||
          elm.value === "9"
        ) {
          elm.value = 0 + elm.value + "/";
        }
      }
      if (len === 4) {
        if (
          elm.value[3] === "2" ||
          elm.value[3] === "3" ||
          elm.value[3] === "4" ||
          elm.value[3] === "5" ||
          elm.value[3] === "6" ||
          elm.value[3] === "7" ||
          elm.value[3] === "8" ||
          elm.value[3] === "9"
        ) {
          elm.value = elm.value.split("/").join("/0") + "/";
        }
      }
      if (len === 2) {
        elm.value += "/";
      }
      if (len === 5) {
        elm.value += "/";
      }
    });
  };

  const handleOk = (value) => {
    let element = {
      target: {
        value: DateTime.fromISO(value).toISODate(),
      },
    };
    formikProps.handleChange(element);
    props.handlers?.onChange(element);
  }

  dateInputMask(input);

  return (
    <div>
      <div className={styles.mui_calendar_wrapper}>
        <DatePicker
          minDate={(options?.minClaimDate ? DateTime.fromJSDate(options.minClaimDate) : DateTime.now().minus({ years: 7 }))}
          maxDate={(options?.maxClaimDate ? DateTime.fromJSDate(options.maxClaimDate) : DateTime.now())}
          value={DateTime.fromJSDate(options?.treatmentDate || new Date())}
          onChange={(value) => {
            setDate(value);
            handleOk(value);
          }}
          sx={{
            '.MuiInputBase-root.MuiOutlinedInput-root': {
              '& input': {
                paddingTop: '8px',
                paddingBottom: '8px',
                borderWidth: '1px',
                fontFamily: "FSElliotRegular",
                fontSize: "18px",
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#009577',
                borderWidth: '1px',
                borderRadius: '4px',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#009577',
                borderWidth: '1px',
                borderRadius: '4px',
              },
            },
          }}
          slotProps={{
            day: {
              sx: {
                '&.MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#ABF7E8',
                  color: '#000'
                },
                ':hover': {
                  background: '#00f0e0',
                },
                '&.MuiPickersYear-yearButton.Mui-selected': {
                  background: '#00f0e0',
                }
              }
            },
            layout: {
              sx: {
                '.MuiDateCalendar-root .MuiPickersYear-root .Mui-selected': {
                  backgroundColor: '#00f0e0',
                  color: '#000'
                },
              }
            }
          }}
        />
      </div>
      {date === null ? (
        <div className={styles.error_message}>
          <img src={errorImage} className={styles.error_image} alt="Error" />
          Date cannot be before {options?.id === 4 ? 'Accident Date' : formatDateForMessage(new Date(minDate))} and after {formatDateForMessage(new Date(maxDate))}
        </div>
      ) : null}
    </div>
  );
};
