import { urlPrefix } from '@shared/constants/site-constants';

export interface RoutePath {
  path: string;
}

export interface RoutePaths {
  [key: string]: RoutePath;
}

export const routePaths: RoutePaths = {
  account: { path: `${urlPrefix}/account` },
  register: { path: `/register` },
  emailNotVerified: { path: `/email-not-verified` },
  emailConfirm: { path: `/register/confirm-email` },
  forgotPassword: { path: `/forgot-password` },
  forgotPasswordConfirm: { path: `/confirm-forgot-password` },
  loggedOut: { path: '/logged-out' },
  marketingPreference: { path: `/marketing-preference` },
  appStore: { path: `/app-store` },
};

export const buildFullPath = (rootPath: string, path: string) =>
  `${rootPath}${path}`;

export const useRouteMatchWithChildPath = () => {
  const path = routePaths.account.path;
  return {
    root: { path: path },
    register: { path: buildFullPath(path, routePaths.register.path) },
    emailNotVerified: {
      path: buildFullPath(path, routePaths.emailNotVerified.path),
    },
    emailConfirm: { path: buildFullPath(path, routePaths.emailConfirm.path) },
    forgotPassword: {
      path: buildFullPath(path, routePaths.forgotPassword.path),
    },
    forgotPasswordConfirm: {
      path: buildFullPath(path, routePaths.forgotPasswordConfirm.path),
    },
    loggedOut: { path: buildFullPath(path, routePaths.loggedOut.path) },
    marketingPreference: {
      path: buildFullPath(path, routePaths.marketingPreference.path),
    },
    appStore: { path: buildFullPath(path, routePaths.appStore.path) },
  };
};
