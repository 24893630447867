import { PortalAreaType } from '@authenticated/services/models/control.interfaces';
import { urlPrefix } from '@shared/constants/site-constants';
import {
  RoutePathAccess,
  routePathAccessTypes,
} from './route-paths-access.model';

export const routeParamNames = {
  policyId: ':policyId',
  claimId: ':claimId',
  partnerId: ':partnerId',
};

export interface RoutePath {
  control?: PortalAreaType;
  path: string;
  hidden?: boolean;
  access: RoutePathAccess;
}

export interface RoutePaths {
  [key: string]: RoutePath;
}

export const routePaths: RoutePaths = {
  home: {
    path: `${urlPrefix}/`,
    access: routePathAccessTypes.allCustomers,
  },
  changePolicy: {
    path: `${urlPrefix}/policies/${routeParamNames.policyId}/change`,
    hidden: false,
    access: routePathAccessTypes.allPolicyHoldersWithPolicies,
  },
  policies: {
    path: `${urlPrefix}/policies`,
    hidden: false,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  viewPersonalDetails: {
    path: `${urlPrefix}/personal-details/view`,
    hidden: false,
    access: routePathAccessTypes.allCustomers,
  },
  editPersonalDetails: {
    path: `${urlPrefix}/personal-details/edit`,
    hidden: false,
    access: routePathAccessTypes.allCustomersApartFromDhpOnly,
  },
  viewClaims: {
    path: `${urlPrefix}/claims`,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  viewClaimDetails: {
    path: `${urlPrefix}/claims/${routeParamNames.policyId}/${routeParamNames.claimId}/view`,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  viewClaimFurtherInfo: {
    path: `${urlPrefix}/claims/${routeParamNames.policyId}/${routeParamNames.claimId}/further-info`,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  makeClaim: {
    path: `${urlPrefix}/make-claim`,
    hidden: false,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  securitySettings: {
    path: `${urlPrefix}/security-settings`,
    access: routePathAccessTypes.allCustomers,
  },
  viewBankDetails: {
    path: `${urlPrefix}/bank-details/view`,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  editBankDetails: {
    path: `${urlPrefix}/bank-details/${routeParamNames.policyId}/edit`,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  addBankDetails: {
    path: `${urlPrefix}/bank-details/add`,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  contactAboutBankDetails: {
    path: `${urlPrefix}/bank-details/${routeParamNames.policyId}/contact-us`,
    access:
      routePathAccessTypes.allPolicyholdersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  documents: {
    path: `${urlPrefix}/documents`,
    access: routePathAccessTypes.allPolicyHoldersWithPolicies,
  },
  viewDocument: {
    path: `${urlPrefix}/view-document`,
    access: routePathAccessTypes.allPolicyHoldersWithPolicies,
  },
  viewReceipt: {
    path: `${urlPrefix}/view-receipt`,
    access: routePathAccessTypes.allPolicyHoldersWithPolicies,
  },
  wellbeing: {
    path: `${urlPrefix}/wellbeing`,
    access: routePathAccessTypes.allCustomersApartFromDhpOnly,
  },
  onboard: {
    path: `${urlPrefix}/onboard`,
    access: routePathAccessTypes.allCustomersWithPolicies,
  },
  matched: {
    path: `${urlPrefix}/matched`,
    access: routePathAccessTypes.allCustomersWithPolicies,
  },
  apps: {
    path: '/apps',
    access: routePathAccessTypes.allPolicyHoldersWithPolicies,
  },
  faqs: {
    path: 'https://www.simplyhealth.co.uk/frequently-asked-questions/individuals-faq',
    access: routePathAccessTypes.allCustomersWithPolicies,
  },
  simplyRewards: {
    path: 'https://www.simplyhealth.co.uk/simplyrewards',
    access: routePathAccessTypes.allCustomersWithPoliciesApartFromGPOnly,
  },
  referAFriend: {
    path: 'https://www.simplyhealth.co.uk/existing-customers/refer-a-friend',
    access:
      routePathAccessTypes.allCustomersWithPoliciesApartFromGPOnlyAndDhpOnly,
  },
  scan: {
    path: 'https://www.simplyhealth.co.uk/existing-customers/diagnostics-and-scans',
    access: routePathAccessTypes.allCustomers,
  },
  physio: {
    path: 'https://simplyhealth.ascenti.co.uk/',
    access: routePathAccessTypes.allCustomers,
  },
  inviteDependant: {
    path: `${urlPrefix}/add-dependant`,
    access: routePathAccessTypes.allPolicyHoldersWithPolicies,
  },
  phioService: {
    path: `${urlPrefix}/phio-service`,
    access: routePathAccessTypes.allCustomersWithPoliciesApartFromGPOnly,
  },
  signOut: {
    path: `${urlPrefix}/signOut`,
    access: routePathAccessTypes.allCustomers,
  },
  gpService: {
    path: `${urlPrefix}/gp-service`,
    access: routePathAccessTypes.allCustomers,
  },
  healthAssuredService: {
    path: `${urlPrefix}/health-assured-service`,
    access: routePathAccessTypes.allHealthPlanCustomersWithPolicies,
  },
  myHealth: {
    path: `${urlPrefix}/my-health`,
    access: routePathAccessTypes.allCustomersWithPolicies,
  },
  symptomChecker: {
    path: `${urlPrefix}/symptom-checker`,
    access: routePathAccessTypes.allHealthPlanCustomersWithPolicies,
  },
  partnerList: {
    path: `${urlPrefix}/partner-list`,
    access: routePathAccessTypes.allCustomersWithPolicies,
  },
  howCanWeHelp: {
    path: `${urlPrefix}/how-can-we-help`,
    access: routePathAccessTypes.allHealthPlanCustomersWithPolicies,
  },
  healthilyLibrary: {
    path: 'https://www.livehealthily.com/health-library?utm_source=simplyhealth&utm_campaign=pilot-test2',
    access: routePathAccessTypes.allCustomers,
  },
};
