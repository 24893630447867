import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import "normalize.css";
import "flatpickr/dist/themes/light.css";
import "./index.scss";
import App from "./App";
import { AppStateContextProvider } from "@shared/state/AppStateProvider";
import { createInitialState } from "@shared/state/models/app-state.model";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppStateContextProvider initialState={createInitialState()}>
      <App />
    </AppStateContextProvider>
  </React.StrictMode>,
);
