import AppleStore from '@assets/images/apple-store.svg';
import GooglePlay from '@assets/images/google-play.svg';
import { PageName } from '@authenticated/page-constants';
import { appleLink, googleLink } from '@shared/constants/site-constants';
import {
  setEventAnalytics,
  usePageAnalytics,
} from '@shared/services/analytics/analytics-hooks';
import { Layout } from '@unauthenticated/parts/Layout';
import { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import styles from './AppStoreLinker.module.scss';

const PAGE_NAME: PageName = 'AppStoreLinks';

export const AppStoreLinker = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  usePageAnalytics(PAGE_NAME);

  useEffect(() => {
    if (isAndroid) {
      setEventAnalytics(['event106']);
      window.location.replace(googleLink);
    } else if (isIOS) {
      setEventAnalytics(['event107']);
      window.location.replace(appleLink);
    } else {
      setLoaded(true);
    }
  }, []);

  return (
    <>
      {loaded && (
        <Layout title="SimplyPlan App Store Links">
          <div className={styles.container}>
            <div className={styles.store_link}>
              <a
                data-testid="Google_Download_Button"
                onClick={() => {
                  setEventAnalytics(['event106']);
                }}
                href={googleLink}
                target="_blank"
                rel="noreferrer">
                <img src={GooglePlay} alt="Google play store link" />
              </a>
            </div>
            <div className={styles.store_link}>
              <a
                data-testid="Apple_Download_Button"
                onClick={() => {
                  setEventAnalytics(['event107']);
                }}
                href={appleLink}
                target="_blank"
                rel="noreferrer">
                <img src={AppleStore} alt="Apple app store link" />
              </a>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};
