import { get } from "@shared/services/api.service";
import { randomTimeOut, shouldReject } from "@shared/services/helpers/fake-service.helpers";
import { GetStatusResponse } from "./models/status.interfaces";

export const getStatus = async (): Promise<GetStatusResponse> => {
  const baseUrl = process.env.REACT_APP_GROUP_API_BASE_URL;
  const featureUrl = "/status";
  let queryParameters: any = {};

  return get<GetStatusResponse>(baseUrl, featureUrl, queryParameters);
};

export const getFakeStatus = (): Promise<GetStatusResponse> => {
  const fakeServiceCall = new Promise<GetStatusResponse>((resolve, reject) => {
    setTimeout(() => {
      if (shouldReject()) {
        reject("Random reject of promise in getPersonalDetails");
      } else {
        resolve({
          id: "123456",
          type: "status",
          attributes: {
            available: false
          }
        });
      }
    }, randomTimeOut());
  });
  return fakeServiceCall;
};
