import {
  AccessControl,
  createInitialAccessControl,
} from '@authenticated/services/models/access-control.models';
import { MemberLoaded } from '../actions/actions';
import {
  createInitialClaimStateModel,
  MakeAClaimStateModel,
} from './claim-state.model';
import { UserModel } from './user.model';

export interface AppStateModel {
  user?: UserModel;
  memberIds?: Array<string>;
  memberBenefits: Array<string>;
  memberType?: MemberType;
  accessControl: AccessControl;
  loginRecorded: boolean;
  memberLoaded: MemberLoaded;
  emailVerified: boolean;
  updatesAvailable: boolean;
  makeAClaim: MakeAClaimStateModel;
  modalToDisplay: ModalType;
}

export const createInitialState = (): AppStateModel => {
  return {
    loginRecorded: false,
    memberLoaded: 'Loading',
    accessControl: createInitialAccessControl(),
    emailVerified: true,
    updatesAvailable: true,
    makeAClaim: createInitialClaimStateModel(),
    memberBenefits: [],
    modalToDisplay: 'None',
  };
};

export type MemberType = 'Pulse' | 'Ski' | 'Dhp';

export type ModalType = 'None' | 'CancelledPolicies';
