import { formatNumber, roundNumber } from '@shared/helpers/number-helpers';
import { ItunesResponse } from '../models/itunes.models';

export const mapItunesResponse = (response: {
  results: Array<{
    userRatingCountForCurrentVersion: number;
    averageUserRatingForCurrentVersion: number;
  }>;
}): ItunesResponse => {
  return {
    numberOfReviews: formatNumber(
      response.results[0].userRatingCountForCurrentVersion,
    ),
    appRating: roundNumber(response.results[0].averageUserRatingForCurrentVersion, 1),
  };
};
