import { mapMemberResponse } from '@authenticated/member/mappers/member.mappers';
import { queryGCTime, queryStaleTime } from '@shared/constants/site-constants';
import { useQueries, useQuery } from '@tanstack/react-query';
import { getMemberIdForPolicy } from '../helpers/load-member.helper';
import {
  mapMemberPoliciesResponse,
  MapMemberPoliciesResponseOptions,
} from '../mappers/policy-list.mapper';
import {
  getEntitlements,
  getMember,
  getMemberBenefits,
  getMemberPolicies,
} from '../member.service';
import { PolicyDataModel } from '../models/policy-data.models';
import { MemberPolicyListModel } from '../models/policy-list.models';

export const memberQueryCacheKeys = {
  entitlements: 'entitlements',
  memberEntitlements: 'memberEntitlements',
  memberPolicies: 'memberPolicies',
  member: 'member',
};

export const useEntitlementsQuery = (
  selectedMemberId: string,
  policyId: string,
  benefitsAsOfDate: Date,
  memberPolicies: MemberPolicyListModel,
  policy?: PolicyDataModel,
) => {
  const memberId = getMemberIdForPolicy(memberPolicies, policyId);
  let checkSelectedMemberId = '';
  if (policy && policy.id === policyId) {
    checkSelectedMemberId =
      policy.members.policyholder.id === selectedMemberId
        ? selectedMemberId
        : '';
    if (!checkSelectedMemberId) {
      checkSelectedMemberId =
        policy.members.dependants.filter(dep => {
          return dep.id === selectedMemberId;
        }).length > 0
          ? selectedMemberId
          : '';
    }
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: [
      memberQueryCacheKeys.entitlements,
      { checkSelectedMemberId, policyId, benefitsAsOfDate, memberId },
    ],
    queryFn: () =>
      getEntitlements(
        checkSelectedMemberId,
        policyId,
        benefitsAsOfDate,
        memberId,
      ),
    enabled:
      !!checkSelectedMemberId && !!policyId && !!benefitsAsOfDate && !!memberId,
    staleTime: queryStaleTime,
    gcTime: queryGCTime,
    refetchOnWindowFocus: false,
  });

  return {
    entitlementsData: data,
    entitlementsIsError: isError,
    entitlementsIsLoading: isLoading,
  };
};

export const useEntitlementsQueryForDependants = (
  dependantId: string,
  policyId: string,
  benefitsAsOfDate: Date,
) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [
      memberQueryCacheKeys.entitlements,
      { dependantId, policyId, benefitsAsOfDate },
    ],
    queryFn: () =>
      getEntitlements(dependantId, policyId, benefitsAsOfDate, dependantId),
    enabled: !!policyId && !!benefitsAsOfDate && !!dependantId,
    staleTime: queryStaleTime,
    gcTime: queryGCTime,
    refetchOnWindowFocus: false,
  });

  return {
    entitlementsData: data,
    entitlementsIsError: isError,
    entitlementsIsLoading: isLoading,
  };
};

export const useMemberBenefitsQueries = (memberIds: Array<string>) => {
  const benefits = useQueries({
    queries: memberIds.map(memberId => {
      return {
        queryKey: [memberQueryCacheKeys.memberEntitlements, { memberId }],
        queryFn: () =>
          getMemberBenefits(memberId).then(response =>
            response.data.map(data => data.id),
          ),
        enabled: !!memberId,
        staleTime: queryStaleTime,
        gcTime: queryGCTime,
        refetchOnWindowFocus: false,
      };
    }),
    combine: results => {
      return {
        data: results.flatMap(result => result.data),
        isLoading: results.some(result => result.isLoading),
      };
    },
  });

  return {
    memberBenefitsData: benefits.data,
    memberBenefitsIsLoading: benefits.isLoading,
  };
};

export const useMemberPoliciesQuery = (
  memberIds: Array<string>,
  options: MapMemberPoliciesResponseOptions,
) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [memberQueryCacheKeys.memberPolicies, options],
    queryFn: () =>
      getMemberPolicies().then(response =>
        mapMemberPoliciesResponse(response, memberIds, options),
      ),
    enabled: memberIds.length > 0,
    staleTime: queryStaleTime,
    gcTime: queryGCTime,
    refetchOnWindowFocus: false,
  });
  return {
    memberPoliciesData: data,
    memberPoliciesIsError: isError,
    memberPoliciesIsLoading: isLoading,
  };
};

export const useMemberQuery = (memberId: string) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: [memberQueryCacheKeys.member, { memberId }],
    queryFn: () =>
      getMember(memberId).then(response => mapMemberResponse(response)),
    enabled: !!memberId,
    staleTime: queryStaleTime,
    gcTime: queryGCTime,
    refetchOnWindowFocus: false,
  });
  return {
    memberData: data,
    memberIsError: isError,
    memberIsLoading: isLoading,
  };
};
