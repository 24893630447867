import { Fragment, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { Layout } from "./parts/Layout";
import { PageStatus } from "../../page-constants";
import { SubmitButton } from "../../parts/buttons/SubmitButton";
import { routePaths } from "../../routing/models/route-paths";
import { FieldElement } from "@authenticated/parts/forms/FieldElement";
import { buildPath } from "@shared/helpers/build-dynamic-path";
import { usePageAnalytics } from "@shared/services/analytics/analytics-hooks";
import styles from "./MarketingPreference.module.scss";
import { useStateContext } from "@shared/state/AppStateProvider";
import { mapUpdateMemberRequest, mapSetMemberPreference } from "../../member/mappers/member.mappers";
import { MemberDetailsModel, getInitialMemberDetailsModel } from "../../member/models/member-details.model";
import { updateMember } from "../../services/member.service";
import { preferences } from "../../member/pages/edit/parts/form-meta/preferences.form-config";
import { errorToast, successToast } from "@shared/helpers/toast";
import { memberQueryCacheKeys, useMemberQuery } from "../../services/queries/member.query";
import { useQueryClient } from "@tanstack/react-query";

const PAGE_NAME = "OnboardingMarketingPreferences";

export const MarketingPreference = () => {
  usePageAnalytics(PAGE_NAME);
  const [memberDetails, setMemberDetails] = useState<MemberDetailsModel>(getInitialMemberDetailsModel());
  const [pageStatus, setPageStatus] = useState<PageStatus>("Loading");
  const [redirectTo, setRedirectTo] = useState<string>("");

  const { memberIds } = useStateContext();

  const queryClient = useQueryClient();

  const { memberData } = useMemberQuery(memberIds ? memberIds[0] : "");

  useEffect(() => {
    if (memberData) {
      setMemberDetails(memberData);
      setPageStatus("Loaded");
    }
  }, [memberData]);

  const initialValues = {
    preferences: {
      marketing: {
        email: "false",
        phone: "false",
        post: "false",
        sms: "false",
      },
    },
  };

  const onSubmit = (values: any) => {
    if (memberDetails.member.id !== "") {
      memberDetails.preferences.marketing = values.preferences.marketing;
      setPageStatus("Sending");
      updateMember(
        memberDetails.member.id,
        mapUpdateMemberRequest(memberDetails.member.id, memberDetails, mapSetMemberPreference(memberDetails, values))
      )
        .then(() => {
          queryClient.removeQueries({
            queryKey: [memberQueryCacheKeys.member],
            exact: false,
          });
          successToast("Marketing preferences updated.", PAGE_NAME);
        })
        .catch((error) => {
          console.error(error);
          setPageStatus("Error Loading");
          errorToast("Marketing preferences failed to update.", PAGE_NAME);
        })
        .finally(() => {
          setPageStatus("Next Page");
          setRedirectTo(buildPath(routePaths.home, []));
        });
    } else {
      errorToast("Marketing preferences failed to update.", PAGE_NAME);
      setPageStatus("Next Page");
      setRedirectTo(buildPath(routePaths.home, []));
    }
  };
  return (
    <Fragment>
      {pageStatus === "Next Page" ? (
        <Navigate to={redirectTo} />
      ) : (
        <Layout
          title="Just one more thing..."
          imageSource="woman-with-pencil.svg"
          imageAltText="Woman-With-Pencil Illustration"
        >
          <p className={styles.info}>We just need to ask for your marketing preferences before you can sign in</p>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
              <Form>
                <div>
                  <Fragment>
                    <p>
                      By listening to our customers, Simplyhealth Group will continue making improvements and
                      introducing new products and services to help you and your loved ones stay as healthy as you can.
                      We'd love to share this sort of information with you.
                    </p>
                    <p>
                      We'll always treat your personal details with the utmost care and we'll never pass them on to
                      other companies.
                    </p>

                    <p>
                      <b>Marketing preferences</b>
                      <br />
                      Please let us know how you'd like to hear from us:
                    </p>
                    <div className={styles.form}>
                      <FieldElement
                        options={{ pageName: PAGE_NAME, registerLabel: true }}
                        field={{
                          ...preferences.marketing.email,
                          initialValues: initialValues.preferences.marketing.email,
                        }}
                        fieldNamePrefix="preferences.marketing"
                      />
                      <FieldElement
                        options={{ pageName: PAGE_NAME, registerLabel: true }}
                        field={{
                          ...preferences.marketing.phone,
                          initialValues: initialValues.preferences.marketing.phone,
                        }}
                        fieldNamePrefix="preferences.marketing"
                      />
                      <FieldElement
                        options={{ pageName: PAGE_NAME, registerLabel: true }}
                        field={{ ...preferences.marketing.sms, initialValues: initialValues.preferences.marketing.sms }}
                        fieldNamePrefix="preferences.marketing"
                      />
                      <FieldElement
                        options={{ pageName: PAGE_NAME, registerLabel: true }}
                        field={{
                          ...preferences.marketing.post,
                          initialValues: initialValues.preferences.marketing.post,
                        }}
                        fieldNamePrefix="preferences.marketing"
                      />
                    </div>
                  </Fragment>
                  <div className={styles.button_container}>
                    <div className={styles.button}>
                      <SubmitButton
                        buttonClickedText="Saving..."
                        buttonText="Save preferences"
                        buttonName="savePreferences"
                        pageName={PAGE_NAME}
                        pageStatus={pageStatus}
                        styling="send"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Layout>
      )}
    </Fragment>
  );
};
