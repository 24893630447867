import { Fragment } from "react";
import { Button } from "../../parts/buttons/Button";
import styles from "./NotFound.module.scss";
import errorImage from "@assets/images/404.svg";
import { setEventAnalytics, usePageAnalytics } from "@shared/services/analytics/analytics-hooks";
import { useNavigate } from "react-router-dom";
import { PageName } from "../../page-constants";

const PAGE_NAME: PageName = "NotFound";

export const NotFound = () => {
  const navigate = useNavigate();
  usePageAnalytics(PAGE_NAME);

  return (
    <Fragment>
      <div className={styles.container}>
        <img src={errorImage} alt="" />
        <h1 className={styles.title} data-testid="title">Page not found</h1>
        <p data-testid="text-1">Oops. We know plans, and this wasn't one of them!</p>
        <p data-testid="text-2">Let's get you back to a page we know exists.</p>
        <div className={styles.button_container}>
          <Button
            type="button"
            styling="primary"
            onClickHandler={() => {
              setEventAnalytics(["event108"]);
              navigate(-1);
            }}
            pageName={PAGE_NAME}
            name="takeMeBack"
          >
            Take me back
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
