import { numberOfPreviousClaimingYears } from '@shared/constants/site-constants';
import { isDHPMember } from '@shared/helpers/policy-helpers';
import {
  MemberPoliciesResponse,
  MemberPoliciesResponseData,
  MemberPoliciesResponseIncludedMembers,
  MemberPoliciesResponseIncludedPayers,
  MemberPoliciesResponseIncludedProducts,
} from '../models/member-service.interfaces';
import {
  MemberPolicyListModel,
  PolicySummaryModel,
} from '../models/policy-list.models';

export interface MapMemberPoliciesResponseOptions {
  onlyMapPolicyholder: boolean;
  excludeDHPPolicies: boolean;
}

export const mapMemberPoliciesResponse = (
  response: MemberPoliciesResponse,
  memberIds: Array<string>,
  options: MapMemberPoliciesResponseOptions,
  asOfDate?: Date,
): MemberPolicyListModel => {
  const policyDataArray: Array<PolicySummaryModel> = response.data
    .filter(policy =>
      options.onlyMapPolicyholder
        ? isPolicyAvailable(memberIds, policy, asOfDate)
        : true,
    )
    .filter(policy =>
      isDHPMember(policy.relationships.policyholder.data.id) &&
      options.excludeDHPPolicies
        ? false
        : true,
    )
    .map(policy => {
      const mappedPolicy: PolicySummaryModel = {
        id: policy.id,
        status: policy.attributes.status === 'ACTIVE' ? 'Active' : 'Cancelled',
        reference: policy.attributes.reference,
        product: isDHPMember(policy.relationships.policyholder.data.id)
          ? { id: 'dhp', name: 'Simply Essentials' }
          : {
              id: policy.relationships.products.data[0].id,
              name: findProductData(
                policy.relationships.products.data[0].id,
                response.included,
              ).attributes.name,
            },
        start: policy.attributes.start,
        cancelled: policy.attributes.cancellation,
        source: policy.meta.source.system === 'SKI' ? 'Ski' : 'Pulse',
        policyholderId: policy.relationships.policyholder.data.id,
        isDirectCredit: policy.attributes.hasDCDetails,
        coverStart: policy.attributes.coverStart,
      };
      return mappedPolicy;
    });
  const sortedPolicies = policyDataArray.sort(
    (a, b) =>
      a.status.localeCompare(b.status) ||
      new Date(b.start).getTime() - new Date(a.start).getTime(),
  );
  return { policies: sortedPolicies };
};

const isPolicyAvailable = (
  memberIds: Array<string>,
  policy: MemberPoliciesResponseData,
  asOfDate?: Date,
): boolean => {
  if (
    policy.relationships.policyholder &&
    memberIds.includes(policy.relationships.policyholder.data.id)
  ) {
    if (policy.attributes.status === 'ACTIVE') {
      return true;
    } else {
      const dateLimit = asOfDate ? asOfDate : new Date();
      dateLimit.setFullYear(
        dateLimit.getFullYear() - numberOfPreviousClaimingYears,
      );
      if (
        policy.attributes.cancellation &&
        new Date(policy.attributes.cancellation) > dateLimit
      ) {
        return true;
      }
    }
  }
  return false;
};

const findProductData = (
  id: string,
  includedData: Array<
    | MemberPoliciesResponseIncludedPayers
    | MemberPoliciesResponseIncludedMembers
    | MemberPoliciesResponseIncludedProducts
  >,
): MemberPoliciesResponseIncludedProducts => {
  return includedData.filter(product => {
    return product.type === 'products' && product.id === id;
  })[0] as unknown as MemberPoliciesResponseIncludedProducts;
};
