import { Fragment } from "react";
import { PageName, PageStatus } from "@authenticated/page-constants";
import { Button } from "./Button";
import { setEventAnalytics } from "@shared/services/analytics/analytics-hooks";

export type SubmitButtonStyling = "primary" | "send";

export interface SubmitButtonProps {
  pageStatus: PageStatus;
  pageName: PageName;
  buttonName: string;
  buttonText: string;
  buttonClickedText: string;
  analyticsClickEvent?: string;
  disabled?: boolean;
  styling?: SubmitButtonStyling;
}
export const SubmitButton = (props: SubmitButtonProps) => {
  const onClick = () => {
    if (props.analyticsClickEvent) {
      setEventAnalytics([props.analyticsClickEvent]);
    }
  };

  return (
    <Fragment>
      {props.pageStatus === "Sending" ? (
        <Button
          pageName={props.pageName}
          styling={props.styling === "send" ? "send" : "primary"}
          name={props.buttonName}
          type="submit"
          disabled={true}
        >
          {props.buttonClickedText}
        </Button>
      ) : (
        <Button
          pageName={props.pageName}
          styling={props.styling === "send" ? "send" : "primary"}
          name={props.buttonName}
          type="submit"
          onClickHandler={onClick}
          disabled={props.disabled ? props.disabled : false}
        >
          {props.buttonText}
        </Button>
      )}
    </Fragment>
  );
};
