import { PageName } from "@authenticated/page-constants";
import { generateRandomString } from "@shared/helpers/helper-functions";
import { DateTime } from "luxon";
import { analyticsPageNames } from "../models/analytics.model";

declare const window: any;

const userID = generateRandomString();
const today = DateTime.now().toFormat("dd-MM-y");

const getPage = (pageId: PageName): string => {
  return `${analyticsPageNames[pageId].channel}: ${analyticsPageNames[pageId].pageName} `;
};

export const setPageNameAndChannelAnalytics = (currentPage: PageName) => {
  const pageName = getPage(currentPage);
  return {
    channel: analyticsPageNames[currentPage].channel,
    pageName,
  };
};

export const setUpEvents = (events: string[]) => {
  return events.map((event) => (event.startsWith("event") ? `${event}:${today}:${userID}` : event)).join();
};

export const splitString = (string: string, valueToCauseSplit: string) => {
  return string.split(valueToCauseSplit)[0];
};

export const sendAnalytics = () => {
  window.adobeAnalytics.tl();
};

export const analyticsCookies = {
  campaign: "SH_CAMPAIGN",
  attribution: "SH_ATTRIBUTION",
  homepageBanner: "SH_HOMEPAGE_BANNER",
};

export type Query = "cid" | "att" | "homepageBanner";
